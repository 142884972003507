// Angular
import { Injectable } from "@angular/core";
import {
	ActivatedRouteSnapshot,
	CanActivate,
	Router,
	RouterStateSnapshot,
} from "@angular/router";
// RxJS
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
// NGRX
import { select, Store } from "@ngrx/store";
// Auth reducers and selectors
import { AppState } from "../../../core/reducers/";
import { isLoggedIn } from "../_selectors/auth.selectors";
import { GlobalService } from "../../../core/global.service";
import { Location } from "@angular/common";

@Injectable()
export class AuthGuard implements CanActivate {
	constructor(
		private store: Store<AppState>,
		private router: Router,
		private globalservice: GlobalService,
		public location: Location
	) {}

	// canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>  {
	//     return this.store
	//         .pipe(
	//             select(isLoggedIn),
	//             tap(loggedIn => {
	//                 if (!loggedIn) {
	//                     this.router.navigateByUrl('customer/login');
	//                 }
	//             })
	//         );
	// }

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean {
		if (
			localStorage.getItem("currentuser") != null &&
			localStorage.getItem("currentuser") != "" &&
			localStorage.getItem("currentuser") != "null"
		) {
			var udata = JSON.parse(localStorage.getItem("currentuser"));
			this.globalservice.userType = udata["user_info"]["user_type"];
			this.globalservice.checkuser(udata["access_token"]).then(
				(result) => {
					this.globalservice.updateUserCredit(result.credit);
				},
				(err) => {
					if (err.error.message == "Token has expired") {
						this.globalservice.terminateUser();
						return;
					}
					if (err.error.status == "login_attempt_exceed") {
						this.globalservice.terminateUser();
						return;
					}
				}
			);
			return true;
		} else {
			this.globalservice.redirectUrl = state.url;
			this.router.navigate(["/login"]);
			return false;
		}
	}
}
