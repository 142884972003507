import { BaseModel } from '../../_base/crud';
import { Address } from './address.model';
import { SocialNetworks } from './social-networks.model';

export class User extends BaseModel {
    id: number;
    username: string;
    password: string;
    email: string;
    address1: string;
    comment: string;
    address2: string;
    pdate: string;
    //primaryemail: string;
    secondary_email1: string;
    accessToken: string;
    refreshToken: string;
    roles: number[];
    pic: string;
    city: string;
    state: string;
    country: string;
    hdu: string;
    referredby: string;
    traffic: string;
    destinations: string;
    switch: string;
    auth: string;
    ip: string;
    zipcode: string;
    amount: string;
    first_name: string;
    last_name: string;
    pmethod: string;
    tid: string;
    description: string;
    client: string;
    //occupation: string;
	company: string;
	lactive: string;
	lmonthly: string;
	lrate: string;
	tactive: string;
	tmonthly: string;
	trate: string;
	outbound: string;
	inbound: string;
	//phone: string;
    //: Address;
    //socialNetworks: SocialNetworks;

    clear(): void {
        this.id = undefined;
        this.username = '';
        this.address1 = '';
        this.address2 = '';
        this.pdate = '';
        this.password = '';
        this.email = '';
        this.city = '';
        this.state = '';
        this.zipcode = '';
        this.hdu = '';
        this.referredby = '';
        this.traffic = '';
        this.destinations = '';
        this.tid = '';
        this.pmethod = '';
        this.switch = '';
        this.auth = '';
        this.ip = '';
        this.comment = '';
        this.country = '';
        this.description = '';
       // this.primaryemail = '';
        this.secondary_email1 = '';
        this.roles = [];
        this.first_name = '';
        this.client = '';
        this.amount = '';
        this.last_name = '';
        this.accessToken = 'access-token-' + Math.random();
        this.refreshToken = 'access-token-' + Math.random();
       this.pic = './assets/media/users/default.jpg';
        //this.occupation = '';
        this.company = '';
        this.lactive = '';
        this.lmonthly = '';
        this.lrate = '';
        this.tactive = '';
        this.tmonthly = '';
        this.trate = '';
        this.inbound = '';
        this.outbound = '';
       // this.phone = '';
        //this.address = new Address();
        //this.address.clear();
        //this.socialNetworks = new SocialNetworks();
        //this.socialNetworks.clear();
    }
}
