// Angular
import { Component, ElementRef, Input, OnInit, ViewChild,ChangeDetectorRef } from '@angular/core';
// Layout
import { LayoutConfigService } from '../../../../../core/_base/layout';
import { GlobalService } from '../../../../../core/global.service';
import { FormBuilder, FormGroup, Validators ,FormControl} from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material';
import { ToastrService } from 'ngx-toastr';

// Charts
//import { Chart } from 'chart.js/dist/Chart.min.js';

@Component({
	selector: 'kt-widget15',
	templateUrl: './widget15.component.html',
	styleUrls: ['./widget15.component.scss'],
})
export class Widget15Component implements OnInit {
	// Public properties
	public select13days:boolean = false;
	selectedDays = '7';
	privatedays: boolean = false;
	public token = 0;
	public chartLoad;
	public initChart = false;
	selectdayForm = new FormGroup({
		sdays: new FormControl()
	 });
	@Input() title: string;
	@Input() desc: string;
	@Input() data: { labels: string[]; datasets: any[] };
	@ViewChild('chart', {static: true}) chart: ElementRef;

	/**
	 * Component constructor
	 *
	 * @param layoutConfigService: LayoutConfigService
	 */
	constructor(private layoutConfigService: LayoutConfigService, private globalservice: GlobalService,private cd: ChangeDetectorRef,
		public toastr: ToastrService) {
		var udata = JSON.parse(localStorage.getItem('currentuser'));
		this.token = udata['access_token'];
	}

	refresh(){
		this.cd.detectChanges();
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		// this.load7Chart();
	}

	load16Chart(){

			this.data = {
				labels: ['Label 1', 'Label 2', 'Label 3', 'Label 4', 'Label 5', 'Label 6', 'Label 7', 'Label 8', 'Label 9', 'Label 10', 'Label 11', 'Label 12', 'Label 13', 'Label 14', 'Label 15', 'Label 16'],
				datasets: [
					{
						// label: 'dataset 1',
						backgroundColor: this.layoutConfigService.getConfig('colors.state.success'),
						data: [
							15, 20, 25, 30, 25, 20, 15, 20, 25, 30, 25, 20, 15, 10, 15, 20
						]
					}
				]
			};


		this.initChartJS();
	}

	load13Chart(){
		this.chartLoad.data = {
				labels: ['Label 1', 'Label 2', 'Label 3', 'Label 4', 'Label 5', 'Label 6', 'Label 7', 'Label 8', 'Label 9', 'Label 10', 'Label 11', 'Label 12', 'Label 13'],
				datasets: [
					{
						// label: 'dataset 1',
						backgroundColor: this.layoutConfigService.getConfig('colors.state.success'),
						data: [
							15, 20, 25, 30, 25, 20, 15, 20, 25, 30, 25, 20, 15
						]
					}
				]
			};
		 // this.initChartJS();
		this.chartLoad.update();
	}

	load7Chart(){
			this.data = {
				labels: ['Label 1', 'Label 2', 'Label 3', 'Label 4', 'Label 5', 'Label 6', 'Label 7'],
				datasets: [
					{
						backgroundColor: this.layoutConfigService.getConfig('colors.state.success'),
						data: [
							15, 20, 25, 30, 25, 20, 15
						]
					}
				]
			};
		this.initChartJS();
	}


	/** Init chart */
	initChartJS() {
		// For more information about the chartjs, visit this link
		// https://www.chartjs.org/docs/latest/getting-started/usage.html

		this.chartLoad = new Chart(this.chart.nativeElement, {
			type: 'bar',
			data: this.data,
			options: {
				title: {
					display: false,
				},
				tooltips: {
					intersect: false,
					mode: 'nearest',
					xPadding: 10,
					yPadding: 10,
					caretPadding: 10
				},
				legend: {
					display: false
				},
				responsive: true,
				maintainAspectRatio: false,
				barRadius: 4,
				scales: {
					xAxes: [{
						display: false,
						gridLines:{color:'rgba(0,0,0,0.07)',borderDash:[],},
						stacked: true
					}],
					yAxes: [{
						display: true,
						stacked: true,
						gridLines:{color:'rgba(0,0,0,0.07)',borderDash:[],}
					}]
				},
				layout: {
					padding: {
						left: 0,
						right: 0,
						top: 0,
						bottom: 0
					}
				},
				elements: {
					rectangle: {
						borderWidth:1
					},
				}
			}
		});
	}

	/* changeday(count){

		console.log(count);
		if(count == 7){
			this.load7Chart();
		}else if(count == 16){
			this.load16Chart();
		}else if(count == 13){
			this.load13Chart();
		}
	} */

	changeday(event, count){
		if(event.isUserInput){
			this.loadChart(event.source.value);
		}
	}

	loadChart(days){
		//var obj = this;
		this.globalservice.getChartRating(this.token,days,'SMS').then((result) =>{
			if(result != null){
				if(!this.initChart){
					this.data = result['result'];
					this.initChartJS();
					this.initChart = true;
				}else{
					this.chartLoad.data = result['result'];
					this.chartLoad.update();
				}
			} else {
				this.globalservice.logout(this.token)
			}
			
		},(err) => {
			if(err.error.status === 'Token is Expired'){
				this.globalservice.terminateUser();
				return;
			}
			if(err.error.status == 'login_attempt_exceed'){
				this.globalservice.terminateUser();
				return;
			}
			this.toastr.error(err.error.message, 'Oops!', {
				progressBar: true,
				positionClass : 'toast-top-right',
				timeOut:15000
			});
		});
	}
}