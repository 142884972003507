// Angular
import { Component, Input, OnInit, ChangeDetectionStrategy,ChangeDetectorRef } from '@angular/core';
// RxJS
import { Observable, Subscription } from 'rxjs';
// NGRX
import { select, Store } from '@ngrx/store';
// State
import { AppState } from '../../../../../core/reducers';
import { currentUser, Logout, User } from '../../../../../core/auth';
import { GlobalService } from '../../../../../core/global.service';
import { ConstantService } from '../../../../../core/constant.service';
import { Router } from '@angular/router';

@Component({
	selector: 'kt-user-profile',
	templateUrl: './user-profile.component.html',
	styleUrls: ['./user-profile.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserProfileComponent implements OnInit {
	public udata;

	@Input() avatar: boolean = true;
	@Input() greeting: boolean = true;
	@Input() badge: boolean;
	@Input() icon: boolean;

	private subscriptions: Subscription[] = []
	public notificationcount:any;
	/**
	 * Component constructor
	 *
	 * @param store: Store<AppState>
	 */

	constructor(private store: Store<AppState>,private globalservice: GlobalService, private router: Router,public constants :ConstantService,private cd: ChangeDetectorRef) {
		this.udata = JSON.parse(localStorage.getItem('currentuser'));
	}

	refresh() {
		this.cd.detectChanges();
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		var obj = this.globalservice;
		var token = this.udata['access_token'];
		var info = this;
		let intervalId = window.setInterval(function(){
			obj.checkuser(token).then((result) => {
				obj.updateUserCredit(result.credit);
				info.refresh();
			}, (err) => {
				window.clearInterval(intervalId);
				if(err.error.message == 'Token has expired'){
					obj.terminateUser();
					return;
				}
				if(err.error.status == 'login_attempt_exceed'){
					obj.terminateUser();
					return;
				}
			});
		}, 5000);
		this.subscriptions.push(this.globalservice.NotificationCount$.subscribe(
			(res)=>{
			  if(res){
				this.notificationcount=res

			  }
			}
		  ))
	}



	/**
	 * Log out
	 */
	logout() {
		// this.store.dispatch(new Logout());
		this.globalservice.logout(this.udata['access_token']).then((result) => {
				localStorage.clear();
				this.globalservice.isLogin = false;
				// if(this.globalservice.userType == 'user'){
				this.router.navigate(['/login']);
				// }else{
				// 	this.router.navigate(['/admin']);
				// }
		   }, (err) => {

			if(err.error.status === 'Token is Expired'){
				this.globalservice.terminateUser();
				return;
			}

			if(err.error.status == 'login_attempt_exceed'){
				this.globalservice.terminateUser();
				return;
			}
			this.globalservice.terminateUser();
		});
	}
	navigate(){
		if(this.udata['user_info']['user_type'] == 'user'){
			this.router.navigate(['/inbound/order-history'])
		} else {
			this.router.navigate(['/admin/did/order-history'])
		}
	}
}
