// Angular
import { Component, Input, OnInit,ChangeDetectorRef } from '@angular/core';
// Lodash
import { shuffle } from 'lodash';
import { GlobalService } from '../../../../../core/global.service';
import { Router } from '@angular/router';

export interface Widget5Data {
	icon?: string;
	pic?: string;
	title: string;
	// desc: string;
	url?: string;
	info?: string;
	largeInfo?: string;
}

@Component({
	selector: 'kt-widget5',
	templateUrl: './widget5.component.html',
	styleUrls: ['./widget5.component.scss']
})
export class Widget5Component implements OnInit {
	// Public properties
	@Input() data: Widget5Data[];
	public token;
	public calls_today_out = 0;
	public calls_today_in = 0;

	public minute_today_out = 0;
	public minute_today_in = 0;

	public expenditure_today_out = 0;
	public expenditure_today_in = 0;

	constructor(private globalservice: GlobalService,private router: Router,private cd: ChangeDetectorRef) {
		if (localStorage.getItem('currentuser') != null) {
			var udata = JSON.parse(localStorage.getItem('currentuser'));
			this.token = udata['access_token'];
		}
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		if (!this.data) {

			var obj = this;
			this.globalservice.getRatingAndCallinfo(this.token).then((result) => {
				
				if(result['total_row'] > 0){
					obj.calls_today_out = result['result'][0]['calls_today_out'];
					obj.calls_today_in = result['result'][0]['calls_today_in'];

					obj.minute_today_out = result['result'][0]['minute_today_out'];
					obj.minute_today_in = result['result'][0]['minute_today_in'];

					obj.expenditure_today_out = result['result'][0]['expenditure_today_out'];
					obj.expenditure_today_in = result['result'][0]['expenditure_today_in'];
					obj.refresh();
				} else {
					obj.calls_today_out = 0
					obj.calls_today_in = 0

					obj.minute_today_out = 0.00
					obj.minute_today_in = 0.00

					obj.expenditure_today_out = 0.00
					obj.expenditure_today_in = 0.00
					obj.refresh();
				}

				obj.data = ([
					{
						icon: 'fa fa-phone-alt',
						pic: './assets/media/products/product6.jpg',
						title: 'Calls Today',
						largeInfo: '<div class="kt-widget5__stats stats_out">\n' +
							' <span class="kt-widget5__number">'+obj.calls_today_out+'</span>\n' +
							' <span class="kt-widget5__sales">Out</span>\n' +
							' </div>\n' +
							' <div class="kt-widget5__stats">\n' +
							' <span class="kt-widget5__number">'+obj.calls_today_in+'</span>\n' +
							' <span class="kt-widget5__votes">In</span>\n' +
							' </div>'
					},
					{
						icon: 'fa fa-clock',
						title: 'Minutes Today',						
						largeInfo: '<div class="kt-widget5__stats stats_out">\n' +
							' <span class="kt-widget5__number">'+obj.minute_today_out+'</span>\n' +
							' <span class="kt-widget5__sales">Out</span>\n' +
							' </div>\n' +
							' <div class="kt-widget5__stats">\n' +
							' <span class="kt-widget5__number">'+obj.minute_today_in+'</span>\n' +
							' <span class="kt-widget5__votes">In</span>\n' +
							' </div>'
					},
					{
						icon: 'fa fa-layer-group',
						title: 'Expenditure Today',						
						largeInfo: '<div class="kt-widget5__stats stats_out">\n' +
							' <span class="kt-widget5__number">'+obj.expenditure_today_out+'</span>\n' +
							' <span class="kt-widget5__sales">Out</span>\n' +
							' </div>\n' +
							' <div class="kt-widget5__stats">\n' +
							' <span class="kt-widget5__number">'+obj.expenditure_today_in+'</span>\n' +
							' <span class="kt-widget5__votes">In</span>\n' +
							' </div>'
					},

				]);
			},(err) =>{
				if(err.error.status === 'Token is Expired'){
					this.globalservice.terminateUser();
					return;
				}
				if(err.error.status === 'login_attempt_exceed'){
					this.globalservice.terminateUser();
					return;
				}
			});
		}
	}
	refresh() {
		this.cd.detectChanges();
	}
}
