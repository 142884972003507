// Angular
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// Material
// Translate
import { TranslateModule } from '@ngx-translate/core';

// NGRX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
// CRUD
import { InterceptService } from '../../../core/_base/crud/';
// Module components
import { adminComponent } from './admin.component';
import { LoginComponent } from './login/login.component';
import { MatSelectModule } from '@angular/material/select';

import {
	MatButtonModule,
	MatCheckboxModule,
	MatFormFieldModule,
	MatInputModule,
	MatRippleModule,
} from '@angular/material';
// Auth
import { AuthEffects, AuthGuard, authReducer, AuthService } from '../../../core/auth';
//import { SettingComponent } from './setting/setting.component';
import { RecaptchaModule } from 'angular-google-recaptcha';

const routes: Routes = [
	{
		path: '',
		component: adminComponent,
		children: [
			{
				path: '',
				redirectTo: 'login',
				pathMatch: 'full',
			},
			{
				path: 'login',
				component: LoginComponent,
				data: { returnUrl: window.location.pathname },
			},
		],
	},
];

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MatButtonModule,
		RouterModule.forChild(routes),
		MatInputModule,
		MatFormFieldModule,
		MatRippleModule,
		MatSelectModule,
		NgbModule,
		MatCheckboxModule,
		TranslateModule.forChild(),
		StoreModule.forFeature('auth', authReducer),
		EffectsModule.forFeature([AuthEffects]),
		RecaptchaModule.forRoot({
			siteKey: '6LfmSMsUAAAAAMoSl3UwBWVApsRoFtcN4ck64q-z',
		}),
	],
	providers: [
		InterceptService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: InterceptService,
			multi: true,
		},
	],
	exports: [adminComponent],
	declarations: [
		adminComponent,
		LoginComponent,
		//SettingComponent
	],
})
export class adminModule {
	static forRoot(): ModuleWithProviders {
		return {
			ngModule: adminModule,
			providers: [AuthService, AuthGuard],
		};
	}
}
