// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router,ParamMap } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { Observable, Subject, from } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Store
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
// Auth
import { AuthNoticeService, AuthService, Login } from '../../../../core/auth';
import { GlobalService } from '../../../../core/global.service';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';


/**
 * ! Just example => Should be removed in development
 */
const DEMO_PARAMS = {
	EMAIL: '',
	PASSWORD: ''
};

@Component({
	selector: 'kt-login',
	templateUrl: './login.component.html',
	encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit, OnDestroy {
	// Public params
	loginForm: FormGroup;
	loading = false;
	isLoggedIn$: Observable<boolean>;
	errors: any = [];

	private unsubscribe: Subject<any>;

	private returnUrl: any;

	// Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	/**
	 * Component constructor
	 *
	 * @param router: Router
	 * @param auth: AuthService
	 * @param authNoticeService: AuthNoticeService
	 * @param translate: TranslateService
	 * @param store: Store<AppState>
	 * @param fb: FormBuilder
	 * @param cdr
	 * @param route: ActivatedRoute
	 */
	constructor(
		private router: Router,
		private auth: AuthService,
		private authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private store: Store<AppState>,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private route: ActivatedRoute,
		private globalservice: GlobalService,
		public toastr: ToastrService,
		public location: Location
	) {
		this.unsubscribe = new Subject();
		this.globalservice.userType = 'admin';
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		this.initLoginForm();
		// redirect back to the returnUrl before login
		this.route.queryParams.subscribe(params => {
			this.returnUrl = params['returnUrl'] || '/';
		});
		this.router.navigate(['login']);
		
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.authNoticeService.setNotice(null);
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

	onScriptLoad() {
        console.log('Google reCAPTCHA loaded and is ready for use!')
    }

    onScriptError() {
        console.log('Something went long when loading the Google reCAPTCHA')
    }

	/**
	 * Form initalization
	 * Default params, validators
	 */
	initLoginForm() {
		// demo message to show

		this.loginForm = this.fb.group({
			email: [DEMO_PARAMS.EMAIL, Validators.compose([
				Validators.required
			])
			],
			password: [DEMO_PARAMS.PASSWORD, Validators.compose([
				Validators.required
			])
			],
			myRecaptcha: [false, Validators.compose([
				Validators.required
			])
			]
		});
	}

	/**
	 * Form Submit
	 */
	submit() {

		const controls = this.loginForm.controls;
		/** check form */
		if (this.loginForm.invalid) {
			/*=== check google-Recaptch. ===*/
			if(!controls['myRecaptcha'].value){
				this.toastr.error('Please Select Recaptcha ', 'Oops!', {
					progressBar: true,
					positionClass : 'toast-top-right',
					timeOut:15000
				});
				return;
			}

			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}

		this.loading = true;

		const authData = {
			email: controls['email'].value,
			password: controls['password'].value
		};

		this.globalservice.checkLogin(authData.email,authData.password).then((result) => {
			localStorage.setItem('currentuser',JSON.stringify(result));
			this.auth.currentUserSubject.next(result)

			this.globalservice.isLogin = true;
			this.loading = false;
			this.router.navigate(['/admin/dashboard']);
			localStorage.removeItem('last_user');
			localStorage.removeItem('checkSwitchPanel');
			}, (err) => {
			this.toastr.error(err.error.message, 'Oops!', {
				progressBar: true,
				positionClass : 'toast-top-right',
				timeOut:15000
			});
			this.loading = false;
		});

	}

	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.loginForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}
}
