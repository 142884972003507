import { AbstractControl, ValidationErrors } from "@angular/forms"

export class PasswordStrengthValidator { static passwordStrength (control: AbstractControl): ValidationErrors | null {

    let value: string = control.value || '';

    if (!value) {
      return null
    }

    if(value.length < 8){
    return { passwordStrength: `Password must be at least 8 characters long` };
    }

    let upperCaseCharacters = /[A-Z]+/g
    if (upperCaseCharacters.test(value) === false) {
      return { passwordStrength: `Password must contain at least one uppercase letter` };
    }

    let lowerCaseCharacters = /[a-z]+/g
    if (lowerCaseCharacters.test(value) === false) {
      return { passwordStrength: `Password must contain at least one lowercase letter` };
    }


    let numberCharacters = /[0-9]+/g
    if (numberCharacters.test(value) === false) {
      return { passwordStrength: `Password must contain letters & numbers` };
    }

    return null;
  }
}