// Angular
import {
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	ViewEncapsulation,
	ViewChild,
	ElementRef,
	ChangeDetectionStrategy,
	Renderer2,
} from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { finalize, takeUntil, tap } from 'rxjs/operators';
// Translate
import { TranslateService } from '@ngx-translate/core';
// NGRX
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
// Auth
import { AuthNoticeService, AuthService, Register, User } from '../../../../core/auth/';
import { Subject } from 'rxjs';
import { ConfirmPasswordValidator } from './confirm-password.validator';
import { PasswordStrengthValidator } from './password-strength.validators';
import { unescapeIdentifier } from '@angular/compiler';
import { GlobalService } from '../../../../core/global.service';
import { ConstantService } from '../../../../core/constant.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MatTableDataSource } from '@angular/material';
import { map } from 'lodash';
import Swal from 'sweetalert2';
import * as $ from 'jquery';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
declare var AzyoViewPort: any;

@Component({
	selector: 'kt-register',
	templateUrl: './register.component.html',
	encapsulation: ViewEncapsulation.None,
})
export class RegisterComponent implements OnInit, OnDestroy {
	registerForm: FormGroup;
	uploadDocumentForm: FormGroup;
	loading = false;
	errors: any = [];
	@ViewChild("wizard", { static: false }) el: ElementRef;
	public hdudescription: boolean = false;
	public userNameError: boolean = false;
	public userNameLoader: boolean = false;
	public emailLoader: boolean = false;
	public emailError: boolean = false;
	public isuserNameFocus: boolean = false;
	public isemailFocus: boolean = false;
	public userName: any = "frg";
	public isAmerica: boolean = true;
	public cityList: any[];
	public mData: any = {};
	public showUsernameError = false;
	closeResult: string;
	public selectedFile = '';
	public photoIdFiles: any[] = [];
	public companyVerificationFiles: any[] = [];
	public documentTypeList: any[];
	private documentCategory: string;
	photoIdDataSource: MatTableDataSource<any>;
	companyVerificationDataSource: MatTableDataSource<any>;
	public AV: any;
	public kyc: any = 0;
	public client_code: any;
	public kyc_enable: number;
	public kycStatusLoading: boolean;
	public ktWizard: any;
	private userNameBlurOnTab: boolean = false;
	displayedColumns = ["filename", "filetype", "description", "action"];
	private unsubscribe: Subject<any>; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	/**
	 * Component constructor
	 *
	 * @param authNoticeService: AuthNoticeService
	 * @param translate: TranslateService
	 * @param router: Router
	 * @param auth: AuthService
	 * @param store: Store<AppState>
	 * @param fb: FormBuilder
	 * @param cdr
	 */
	constructor(
		private authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private router: Router,
		private auth: AuthService,
		private store: Store<AppState>,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private globalservice: GlobalService,
		public toastr: ToastrService,
		public constants: ConstantService,
		public renderer: Renderer2,
		private modalService: NgbModal,
		private elementRef: ElementRef
	) {
		this.unsubscribe = new Subject();
	}

	/*
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		this.getKycPermission();
		this.initRegisterForm();
	}

	handleKycResponse(response: any) {}

	/*
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

	/**
	 * Form initalization
	 * Default params, validators
	 */
	initRegisterForm() {
		this.registerForm = this.fb.group(
			{
				firstname: ['', Validators.compose([Validators.required])],
				address1: ['', Validators.compose([Validators.required])],
				address2: ['', Validators.compose([])],
				primaryemail: ['', Validators.compose([Validators.required, Validators.email])],
				secondaryemail: [
					'',
					Validators.compose([
						// Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')
						Validators.email,
					]),
				],
				username: [
					'',
					Validators.compose([Validators.required, Validators.pattern("^[a-zA-Z0-9_]*$")]),
				],
				password: ['', Validators.compose([Validators.required, PasswordStrengthValidator])],
				confirmPassword: ['', Validators.compose([Validators.required])],
				website: [
					'',
					Validators.compose([
						Validators.pattern(
							/^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/
						),
					]),
				],
				lastname: ['', Validators.compose([Validators.required])],
				zipcode: ['', Validators.compose([Validators.required])],
				companyName: ['', Validators.compose([Validators.required])],
				city: ['', Validators.compose([Validators.required])],
				country: ['United States of America', Validators.compose([Validators.required])],
				state: ['', Validators.compose([Validators.required])],
				ip: ['', Validators.compose([])],
				hdu: ['', Validators.compose([Validators.required])],
				traffic: ['', Validators.compose([Validators.required])],
				referredby: ['', Validators.compose([])],
				destinations: ['', Validators.compose([Validators.required])],
				switch: ['', Validators.compose([Validators.required])],
				auth: ['', Validators.compose([])],
				description: ['', Validators.compose([])],
				telemarketing_domain: ['', Validators.compose([Validators.required])],
				phone: ['', Validators.compose([Validators.required, Validators.minLength(10)])],
				mobile: ['', Validators.compose([Validators.required, Validators.minLength(10)])],
				myRecaptcha: [false, Validators.compose([Validators.required])],
				agree: [false, Validators.compose([Validators.required])],
			},
			{
				validator: ConfirmPasswordValidator.MatchPassword,
			}
		);
	}

	getKycPermission() {
		this.globalservice.getKycPermission().then(
			(result) => {
				this.kyc_enable = result.kycDetails.kyc_enable;
				const kycScriptElement = this.renderer.createElement(
					"script"
				) as HTMLScriptElement;
				kycScriptElement.src = result.kycDetails.kyc_script_url;
				this.renderer.appendChild(
					this.elementRef.nativeElement,
					kycScriptElement
				);
				const kycStyleElement = this.renderer.createElement(
					"link"
				) as HTMLLinkElement;
				kycStyleElement.rel = "stylesheet";
				kycStyleElement.href = result.kycDetails.kyc_style_url;
				this.renderer.appendChild(
					this.elementRef.nativeElement,
					kycStyleElement
				);
			},
			(err) => {
				this.loading = false;
				this.toastr.error(err.error.message, 'Oops!', {
					progressBar: true,
					positionClass: 'toast-top-right',
					timeOut: 15000,
				});
			}
		);
	}

	onScriptLoad() {
		console.log('Google reCAPTCHA loaded and is ready for use!');
	}

	onScriptError() {
		console.log('Something went long when loading the Google reCAPTCHA');
	}

	/**
	 * Form Submit
	 */
	submit() {
		const controls = this.registerForm.controls;
		// check form
		if (controls['agree'].value == false) {
			controls['agree'].setValue('');
		}
		if (this.registerForm.invalid) {
			Object.keys(controls).forEach((controlName) => controls[controlName].markAsTouched());

			if (!controls['myRecaptcha'].value) {
				this.toastr.error('Please Select Recaptcha ', 'Oops!', {
					progressBar: true,
					positionClass: 'toast-top-right',
					timeOut: 15000,
				});
			}

			return;
		}
		this.loading = true;

		/* if (!controls['agree'].value) {
			// you must agree the terms and condition
			// checkbox cannot work inside mat-form-field https://github.com/angular/material2/issues/7891
			this.authNoticeService.setNotice('You must agree the terms and condition', 'danger');
			return;
		} */
		this.mData.email = controls['primaryemail'].value;
		this.mData.secondary_email = controls['secondaryemail'].value;
		this.mData.password = controls['password'].value;
		this.mData.username = controls['username'].value;
		this.mData.website = controls['website'].value;
		this.mData.first_name = controls['firstname'].value;
		this.mData.last_name = controls['lastname'].value;
		this.mData.company = controls['companyName'].value;
		this.mData.address1 = controls['address1'].value;
		this.mData.address2 = controls['address2'].value;
		this.mData.city = controls['city'].value;
		this.mData.state = controls['state'].value;
		this.mData.country = controls['country'].value;
		this.mData.zipcode = controls['zipcode'].value;
		this.mData.hdu = controls['hdu'].value;
		this.mData.traffic = controls['traffic'].value;
		this.mData.referredby = controls['referredby'].value;
		this.mData.destinations = controls['destinations'].value;
		this.mData.switch = controls['switch'].value;

		this.mData.photoID_type = [].concat(this.photoIdFiles.map((item) => item.type));
		this.mData.photoID = [].concat(this.photoIdFiles.map((item) => item.document));
		this.mData.photoID_description = [].concat(this.photoIdFiles.map((item) => item.description));

		this.mData.companyVerification_type = [].concat(
			this.companyVerificationFiles.map((item) => item.type)
		);
		this.mData.company_verification_documents = [].concat(
			this.companyVerificationFiles.map((item) => item.document)
		);
		this.mData.companyVerification_description = [].concat(
			this.companyVerificationFiles.map((item) => item.description)
		);

		this.mData.description = controls["description"].value;
		this.mData.phone = controls["phone"].value;
		this.mData.mobile = controls["mobile"].value;
		this.mData.kyc_number =
			this.mData.kyc_number == undefined ? null : this.mData.kyc_number;
		this.mData.telemarketing_domain =
			controls["telemarketing_domain"].value;
		this.globalservice.signupUser(this.mData).then(
			(result) => {
				this.toastr.success(result.message, 'Success!', {
					progressBar: true,
					positionClass: 'toast-top-right',
					timeOut: 15000,
				});
				this.loading = false;
				this.router.navigate(['/login']);
			},
			(err) => {
				this.loading = false;
				this.toastr.error(err.error.message, 'Oops!', {
					progressBar: true,
					positionClass: 'toast-top-right',
					timeOut: 15000,
				});
			}
		);
	}
	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.registerForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}

	isFieldHasError(controlName: string, validationType: string): boolean {
		const control = this.registerForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result = control.hasError(validationType);
		return result;
	}

	onFocusInputEvent() {
		this.userNameError = false;
		// this.registerForm.controls.password.disable();
		// this.registerForm.controls.confirmPassword.disable();
	}

	onFocusOutUsername(event) {
		if (event.target.value != '' && this.isControlHasError('username', 'pattern') == false) {
			// this.registerForm.controls.password.disable();
			// this.registerForm.controls.confirmPassword.disable();
			this.userNameLoader = true;
			this.globalservice.userNameCheck(event.target.value).then(
				(result) => {
					this.userNameError = false;
					this.userNameLoader = false;
					if (result.status && this.kyc_enable == 1) {
						this.azyoApicall();
					}
				},
				(err) => {
					this.userNameLoader = false;
					if (
						err.error.message == 'Sorry, your username already used.' &&
						event.target.value != ''
					) {
						this.userNameError = true;
						// this.registerForm.controls.password.disable();
						// this.registerForm.controls.confirmPassword.disable();
						this.registerForm.controls.username.setErrors({
							invalid: true,
						});
					}
				}
			);
		} else if (event.target.value == "") {
			this.userNameError = false;
		}
	}

	onUserNameKeyDown(event) {
		if (event.key === "Tab") {
			this.userNameBlurOnTab = true;
		}
	}

	onFocusOutEmailEvent(event) {
		if (event.target.value != '' && this.isControlHasError('primaryemail', 'email') == false) {
			this.emailLoader = true;
			this.globalservice.emailCheck(event.target.value).then(
				(result) => {
					this.emailError = false;
					this.emailLoader = false;
				},
				(err) => {
					this.emailLoader = false;

					if (!err.error.status && event.target.value != "") {
						this.emailError = true;
						this.registerForm.controls.primaryemail.setErrors({
							invalid: true,
						});
					}
				}
			);
		} else {
			this.emailError = false;
		}
	}

	onFocusInputEmail() {
		this.emailError = false;
	}

	initWizard() {
		// Initialize form wizard
		const wizard = new KTWizard(this.el.nativeElement, {
			startStep: 1,
		});

		var obj = this;
		// Validation before going to next page
		wizard.on('beforeNext', function (wizardObj) {
			// https://angular.io/guide/forms
			// https://angular.io/guide/form-validation

			// validate the form and use below function to stop the wizard's step
			// check form

			if (
				wizardObj.currentStep == 1 &&
				(obj.isFieldHasError("username", "required") ||
					obj.isFieldHasError("username", "pattern") ||
					obj.isFieldHasError("password", "required") ||
					obj.isFieldHasError("password", "passwordStrength") ||
					obj.isFieldHasError("confirmPassword", "ConfirmPassword") ||
					obj.isFieldHasError("confirmPassword", "required") ||
					obj.userNameError == true ||
					obj.isuserNameFocus === true ||
					obj.userNameLoader === true)
			) {
				const controls = obj.registerForm.controls;
				controls['username'].markAsTouched();
				controls['password'].markAsTouched();
				controls['confirmPassword'].markAsTouched();
				wizardObj.stop();
			} else if (
				(obj.isFieldHasError("firstname", "required") ||
					obj.isFieldHasError("lastname", "required") ||
					obj.isFieldHasError("companyName", "required") ||
					obj.isFieldHasError("primaryemail", "required") ||
					obj.isFieldHasError("primaryemail", "pattern") ||
					obj.isFieldHasError("address1", "required") ||
					obj.isFieldHasError("city", "required") ||
					obj.isFieldHasError("state", "required") ||
					obj.isFieldHasError("country", "required") ||
					obj.isFieldHasError("zipcode", "required") ||
					obj.isFieldHasError("phone", "required") ||
					obj.isFieldHasError("mobile", "required") ||
					obj.emailError ||
					obj.emailLoader ||
					obj.isemailFocus) &&
				wizardObj.currentStep == 2
			) {
				const controls = obj.registerForm.controls;
				// check form
				Object.keys(controls).forEach((controlName) => controls[controlName].markAsTouched());
				wizardObj.stop();
			} else if (
				(obj.photoIdFiles.length <= 0 || obj.companyVerificationFiles.length <= 0) &&
				wizardObj.currentStep == 3
			) {
				wizardObj.stop();
			} else if (obj.kyc_enable != 1 && wizardObj.currentStep == 3) {
				wizardObj.goTo(5);
			} else if (
				obj.kyc == 0 &&
				wizardObj.currentStep == 4 &&
				obj.kyc_enable == 1
			) {
				wizardObj.stop();
				Swal.fire({
					title: 'Are you sure you want to skip KYC process?',
					icon: 'warning',
					showCancelButton: true,
					confirmButtonText: 'Yes, Skip & continue',
					cancelButtonText: 'No, Do KYC',
					heightAuto: false,
				}).then((result) => {
					if (result.value) {
						wizardObj.goTo(5);
					} else {
						wizardObj.stop();
					}
				});
			} else if (
				(obj.isFieldHasError("hdu", "required") ||
					obj.isFieldHasError("traffic", "required") ||
					obj.isFieldHasError("destinations", "required") ||
					obj.isFieldHasError("switch", "required") ||
					obj.isFieldHasError("telemarketing_domain", "required")) &&
				wizardObj.currentStep == 5
			) {
				const controls = obj.registerForm.controls;
				// check form
				Object.keys(controls).forEach((controlName) => controls[controlName].markAsTouched());
				wizardObj.stop();
			}

			/* if (obj.registerForm.invalid) {
			 	// wizardObj.stop();
			} */
		});

		wizard.on("beforePrev", function (wizardObj) {
			if (obj.kyc_enable != 1 && wizardObj.currentStep == 5) {
				wizardObj.goTo(3);
			}
		});

		// Change event
		wizard.on("change", (wizard) => {
			setTimeout(function () {
				KTUtil.scrollTop();
			}, 500);
		});
	}

	ngAfterViewInit(): void {
		this.initWizard();
	}

	azyoApicall() {
		const root = document.getElementById('kyc-verification');
		if (this.AV) {
			root.innerHTML = '';
		}

		this.AV = new AzyoViewPort(
			root,
			`0000111100001111`,
			this.registerForm.controls.username.value,
			{
				country: `United States`,
				state: `California`,
				document_type: `Driving License`,
			},
			async (results) => {
				if (results) {
					this.kyc = results.kyc_number;
					const base64Data = results.selfie_image;

					let selfie_image = this.convertBase64(base64Data, `${results.kyc_number}.jpeg`);

					this.mData['kyc_number'] = results.kyc_number;
					this.mData['match_precentage'] = results['match precentage'];
					this.mData['selfie_image'] = selfie_image;
				}
			}
		);
	}

	convertBase64(base64String, fileName) {
		let arr = base64String.split(',');
		let mime = arr[0].match(/:(.*?);/)[1];
		let bstr = atob(arr[1]);
		let n = bstr.length;
		let uint8Array = new Uint8Array(n);
		while (n--) {
			uint8Array[n] = bstr.charCodeAt(n);
		}
		let file = new File([uint8Array], fileName, { type: mime });
		return file;
	}

	changefindus() {
		if (this.registerForm.controls['hdu'].value == 'Other') {
			this.hdudescription = true;
		} else {
			this.hdudescription = false;
		}
	}

	changeCountry() {
		if (this.registerForm.controls['country'].value == 'United States of America') {
			this.isAmerica = true;
		} else {
			this.isAmerica = false;
		}
	}

	changeState(event) {
		let state: string = event;
		state = state.replace(' ', '_');
		this.cityList = this.constants.cityList[state];
	}

	numberOnly(event): boolean {
		const charCode = event.which ? event.which : event.keyCode;
		if (charCode == 43 || charCode == 32 || charCode == 45 || charCode == 46) {
			return true;
		}
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
	}

	addDocumentModal(content, category) {
		this.documentTypeList = [];
		this.documentCategory = category;
		if (category == 'photo_id') {
			this.documentTypeList = [
				{ name: "Driver's license (US only)", value: 'Driver license' },
				{ name: 'State issued ID (US only)', value: 'State issued ID' },
				{
					name: 'Bank debit/credit card with photo (US only)',
					value: 'Bank debit/credit card with photo',
				},
				{ name: 'Passport (US or international)', value: 'Passport' },
			];
		} else {
			this.documentTypeList = [
				{
					name: 'Articles of incorporation',
					value: 'Articles of incorporation',
				},
				{
					name: 'EIN assignment letter',
					value: 'EIN assignment letter',
				},
				{
					name: 'Bank statement (Companies or Individuals)',
					value: 'Bank statement',
				},
			];
		}
		this.initAddDocumentForm();
		this.selectedFile = '';
		this.modalService
			.open(content, {
				ariaLabelledBy: 'modal-basic-title',
				backdrop: 'static',
				keyboard: false,
			})
			.result.then(
				(result) => {
					this.closeResult = `Closed with: ${result}`;
				},
				(reason) => {
					this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
				}
			);
	}

	initAddDocumentForm() {
		this.uploadDocumentForm = this.fb.group({
			mode: ['', Validators.compose([Validators.required])],
			description: ['', Validators.compose([Validators.maxLength(250)])],
			uploadedFile: ['', Validators.compose([Validators.required])],
		});
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}

	isAddDocumentFormControlHasError(controlName: string, validationType: string): boolean {
		const control = this.uploadDocumentForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}

	attachmentchange() {
		const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
		this.selectedFile = '';
		if (fileUpload.files.length) {
			if (
				fileUpload.files[0]['type'] == 'image/jpeg' ||
				fileUpload.files[0]['type'] == 'image/png' ||
				fileUpload.files[0]['type'] == 'image/jpg' ||
				fileUpload.files[0]['type'] == 'image/bmp' ||
				fileUpload.files[0]['type'] == 'application/pdf' ||
				fileUpload.files[0]['type'] ==
					'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
				fileUpload.files[0]['type'] == 'text/plain' ||
				fileUpload.files[0]['type'] == 'text/csv' ||
				fileUpload.files[0]['type'] == 'application/vnd.ms-excel' ||
				fileUpload.files[0]['type'] ==
					'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
				fileUpload.files[0]['type'] == 'application/x-zip-compressed' ||
				fileUpload.files[0]['type'] == 'application/msword' ||
				this.validateFile(fileUpload.files[0]['name'])
			) {
				if (fileUpload.files[0].size < 50000000) {
					this.selectedFile = '(' + fileUpload.files[0].name + ')';
				} else {
					fileUpload.value = '';
					this.uploadDocumentForm.get('uploadedFile').reset();
					this.toastr.error('File size must be less than 50MB.', 'Oops!', {
						progressBar: true,
						positionClass: 'toast-top-right',
						timeOut: 15000,
					});
				}
			} else {
				fileUpload.value = '';
				this.uploadDocumentForm.get('uploadedFile').reset();
				this.toastr.error('File is in invalid format.', 'Oops!', {
					progressBar: true,
					positionClass: 'toast-top-right',
					timeOut: 15000,
				});
			}
		}
	}

	private validateFile(name: String) {
		const allowedTypes = [
			'jpeg',
			'jpg',
			'bmp',
			'png',
			'xls',
			'xlsx',
			'csv',
			'zip',
			'pdf',
			'doc',
			'docx',
			'txt',
		];
		var ext = name.substring(name.lastIndexOf('.') + 1);
		return allowedTypes.includes(ext.toLowerCase());
	}

	clearUploadedFiles() {
		this.selectedFile = '';
		this.uploadDocumentForm.get('uploadedFile').reset();
	}

	onAddDocuments() {
		const controls = this.uploadDocumentForm.controls;
		const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
		// check form
		if (this.uploadDocumentForm.invalid) {
			Object.keys(controls).forEach((controlName) => controls[controlName].markAsTouched());
			return;
		}
		const payLoad = {
			document: fileUpload.files[0],
			type: controls['mode'].value,
			description: controls['description'].value,
		};

		if (this.documentCategory == 'photo_id') {
			this.photoIdFiles.push(payLoad);
			this.photoIdDataSource = new MatTableDataSource<any>(this.photoIdFiles);
		} else {
			this.companyVerificationFiles.push(payLoad);
			this.companyVerificationDataSource = new MatTableDataSource<any>(
				this.companyVerificationFiles
			);
		}
		this.cdr.detectChanges();
		this.modalService.dismissAll();
		this.toastr.success('File added successfully', 'Success!', {
			progressBar: true,
			positionClass: 'toast-top-right',
			timeOut: 7000,
		});
	}

	removeFile(index, category) {
		Swal.fire({
			title: 'Are you sure you want to delete?',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes, Delete it!',
			cancelButtonText: 'No, keep it',
		}).then((result) => {
			if (result.value) {
				if (category == 'photo_id') {
					this.photoIdFiles.splice(index, 1);
					this.photoIdDataSource = new MatTableDataSource<any>(this.photoIdFiles);
				} else {
					this.companyVerificationFiles.splice(index, 1);
					this.companyVerificationDataSource = new MatTableDataSource<any>(
						this.companyVerificationFiles
					);
				}
				this.cdr.detectChanges();
				this.toastr.error('File deleted succesfully', 'Removed !', {
					progressBar: true,
					positionClass: 'toast-top-right',
					timeOut: 7000,
				});
			}
		});
	}
}
