export class DataTableItemModel {
    id: number;
	name: string;
	username: string;
	account_no: string;
	company: string;
	email: string;
	balance: number;
	user_status: number;
}
