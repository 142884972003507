import { PermissionGuard } from './core/auth/_guards/permission.guard';
// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// Components
import { BaseComponent } from './views/theme/base/base.component';
import { ErrorPageComponent } from './views/theme/content/error-page/error-page.component';
import { adminComponent } from '../app/views/pages/admin/admin.component';
import { LoginComponent } from '../app/views/pages/admin/login/login.component';
// Auth
import { AuthGuard } from './core/auth';

const routes: Routes = [
	{
		path: 'admin',
		component: adminComponent,

		children: [
			{
				path: '',
				// redirectTo: 'login',
				// pathMatch: 'full',
				component: LoginComponent,
				data: { returnUrl: window.location.pathname },
			},
			{
				path: 'login',
				component: LoginComponent,
				data: { returnUrl: window.location.pathname },
			},
		],
	},

	{
		path: '',
		loadChildren: () =>
			import('../app/views/pages/customer/customer.module').then((m) => m.customerModule),
	},

	{
		path: '',
		component: BaseComponent,
		children: [
			{
				path: 'admin/dashboard',
				data: {role: ['admin']},
				// canActivate: [PermissionGuard],
				loadChildren: () =>
					import('../app/views/pages/dashboard/dashboard.module').then((m) => m.DashboardModule),
			},
			{
				path: 'admin/customers',
				data: {role: ['admin']},

				loadChildren: () =>
					import('../app/views/pages/admin/customers/customers.module').then(
						(m) => m.CustomersModule
					),
			},
			{
				path: 'admin/customer/edit/:id',
				data: {role: ['admin']},
				loadChildren: () =>
					import('../app/views/pages/admin/customers/veprofile/veprofile.module').then(
						(m) => m.VeprofileModule
					),
			},
			{
				path: 'admin/impersonate/:username/:user_type',
				data: {role: ['admin','user']},
				loadChildren: () =>
					import('../app/views/pages/admin/impersonate/impersonate.module').then(
						(m) => m.ImpersonateModule
					),
			},
			{
				path: 'admin/payment',
				data: {role: ['admin']},

				loadChildren: () =>
					import('../app/views/pages/admin/payment/payment.module').then((m) => m.PaymentModule),
			},
			{
				path: 'admin/device-setup',
				data: {role: ['admin']},
				loadChildren: () =>
					import('../app/views/pages/admin/device-whitelist/device-whitelist.module').then(
						(m) => m.DeviceWhitelistModule
					),
			},
			{
				path: 'admin/settings',
				data: {role: ['admin']},

				loadChildren: () =>
					import('../app/views/pages/admin/setting/setting.module').then((m) => m.SettingModule),
			},
			{
				path: 'admin/global-notifications',
				data: {role: ['admin']},
				loadChildren: () =>
					import('../app/views/pages/admin/global-notifications/global-notifications.module').then(
						(m) => m.GlobalNotificationsModule
					),
			},
			{
				path: 'admin/global-notifications/edit/:id',
				data: {role: ['admin']},
				loadChildren: () =>
					import(
						'../app/views/pages/admin/global-notifications/edit-notifications/edit-notifications.module'
					).then((m) => m.EditNotificationsModule),
			},
			// {
			// 	path: 'admin/subscriptions/edit/:id',
			// 	 loadChildren: () => import('../app/views/pages/admin/subscriptions/edit-subscription/edit-subscription.module').then(m => m.EditSubscriptionModule)
			// },
			{
				path: 'admin/emailtemplate',
				data: {role: ['admin']},

				loadChildren: () =>
					import('../app/views/pages/admin/emailtemplate/emailtemplate.module').then(
						(m) => m.EmailtemplateModule
					),
			},
			{
				path: 'admin/subscriptions',
				data: {role: ['admin']},
				loadChildren: () =>
					import('../app/views/pages/admin/subscriptions/subscriptions.module').then(
						(m) => m.SubscriptionsModule
					),
			},
			{
				path: 'admin/emailtemplate/edit/:id',
				data: {role: ['admin']},
				loadChildren: () =>
					import('../app/views/pages/admin/emailtemplate/edittemplate/edittemplate.module').then(
						(m) => m.EdittemplateModule
					),
			},
			{
				path: 'dashboard',
				data: {role: ['user']},

				loadChildren: () =>
					import('../app/views/pages/dashboard/dashboard.module').then((m) => m.DashboardModule),
			},
			{
				path: 'profile',
				data: {role: ['user']},

				loadChildren: () =>
					import('../app/views/pages/customer/profile/profile.module').then((m) => m.ProfileModule),
			},
			{
				path: 'subscriptions',
				data: {role: ['user']},
				loadChildren: () =>
					import('../app/views/pages/customer/subscription/subscription.module').then(
						(m) => m.SubscriptionModule
					),
			},
			{
				path: 'payment',
				data: {role: ['user']},
				loadChildren: () =>
					import('../app/views/pages/customer/payment/payment.module').then((m) => m.PaymentModule),
			},
			{
				path: 'device-setup',
				data: {role: ['user']},

				loadChildren: () =>
					import('../app/views/pages/customer/device-setup/device-setup.module').then(
						(m) => m.DeviceSetupModule
					),
			},
			{
				path: 'voipessentialproxies',
				data: {role: ['user']},

				loadChildren: () =>
					import(
						'../app/views/pages/customer/voipessentialproxies/voipessentialproxies.module'
					).then((m) => m.VoipessentialproxiesModule),
			},
			{
				path: 'inbound',
				data: {role: ['user']},

				loadChildren: () =>
					import('../app/views/pages/customer/inbound/inbound.module').then((m) => m.InboundModule),
			},
			{
				path: 'calllogs',
				data: {role: ['user']},

				loadChildren: () =>
					import('../app/views/pages/customer/calllogs/calllogs.module').then(
						(m) => m.CalllogsModule
					),
			},
			{
				path: 'sms',
				data: {role: ['user']},
				loadChildren: () =>
					import('../app/views/pages/customer/sms/sms.module').then((m) => m.SmsModule),
			},
			{
				path: 'my-rates',
				data: {role: ['user']},
				loadChildren: () =>
					import('../app/views/pages/customer/myrates/myrate.module').then((m) => m.MyrateModule),
			},
			{
				path: 'admin/did',
				data: {role: ['admin']},
				loadChildren: () =>
					import('../app/views/pages/admin/did/did.module').then((m) => m.DidModule),
			},
			{
				path: 'support/createsupport',
				data: {role: ['user']},
				loadChildren: () =>
					import('../app/views/pages/customer/support/support.module').then((m) => m.SupportModule),
			},
			{
				path: 'caller-reputation',
				data: {role: ['user']},
				loadChildren: () =>
					import('../app/views/pages/customer/caller-reputation/caller-reputation.module').then(
						(m) => m.CallerReputationModule
					),
			},

			// {
			// path: 'mail',
			// loadChildren: () => import('app/views/pages/apps/mail/mail.module').then(m => m.MailModule)
			// },
			// {
			// path: 'ecommerce',
			// loadChildren: () => import('app/views/pages/apps/e-commerce/e-commerce.module').then(m => m.ECommerceModule),
			// },
			// {
			// path: 'ngbootstrap',
			// loadChildren: () => import('app/views/pages/ngbootstrap/ngbootstrap.module').then(m => m.NgbootstrapModule)
			// },
			// {
			// path: 'material',
			// loadChildren: () => import('app/views/pages/material/material.module').then(m => m.MaterialModule)
			// },
			// {
			// path: 'user-management',
			// loadChildren: () => import('app/views/pages/user-management/user-management.module').then(m => m.UserManagementModule)
			// },
			// {
			// path: 'wizard',
			// loadChildren: () => import('app/views/pages/wizard/wizard.module').then(m => m.WizardModule)
			// },
			/* {
				path: 'builder',
				 loadChildren: () => import('app/views/theme/content/builder/builder.module').then(m => m.BuilderModule)
			}, */
			/* {
				path: 'customers',
				 loadChildren: () => import('app/views/theme/content/customers/customers.module').then(m => m.CustomersModule)
			}, */
			{
				path: 'error/403',
				component: ErrorPageComponent,
				data: {
					type: 'error-v6',
					code: 403,
					title: '403... Access forbidden',
					desc:
						"Looks like you don't have permission to access for requested page.<br> Please, contact administrator",
				},
			},
			{ path: 'error/:type', component: ErrorPageComponent },
			{ path: '', redirectTo: 'dashboard', pathMatch: 'full' },
			{ path: '**', redirectTo: 'dashboard', pathMatch: 'full' },
		],
	},

	{ path: '**', redirectTo: 'error/403', pathMatch: 'full' },
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
