// Angular
import { Component } from '@angular/core';
import { FormGroup,FormControl } from '@angular/forms';

/**
 * Sample context menu dropdown
 */
@Component({
	selector: 'kt-context-menu2',
	templateUrl: './context-menu2.component.html',
	styleUrls: ['./context-menu2.component.scss']
})

export class ContextMenu2Component {

	//selectdayForm: FormGroup;
	/* public select13days:boolean = false;

	selectdayForm = new FormGroup({
		sdays: new FormControl()
	 });

	 changeday(){
		if(this.selectdayForm.controls['sdays'].value == 'thirty'){
			this.select13days = true;
		}else{
			this.select13days = false;
		}
	} */



}
