// Angular
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GlobalService } from '../../../../core/global.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
// import { debug } from 'util';

/**
 * More information there => https://medium.com/@MetonymyQT/angular-http-interceptors-what-are-they-and-how-to-use-them-52e060321088
 */
@Injectable()
export class InterceptService implements HttpInterceptor {
	// intercept request and add token
	constructor(
		private globalservice: GlobalService,
		private router: Router,
		public toastr: ToastrService,
		){}
	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		// tslint:disable-next-line:no-debugger
		// modify request
		// request = request.clone({
		// 	setHeaders: {
		// 		Authorization: `Bearer ${localStorage.getItem('accessToken')}`
		// 	}
		// });
		// console.log('----request----');
		// console.log(request);
		// console.log('--- end of request---');

		return next.handle(request).pipe(
			tap(
				event => {
					 if (event instanceof HttpResponse) {
						// console.log('all looks good');
						// http response status code
						// console.log(event.status);
					}
				},
				error => {
					// http response status code
					// console.log('----response----');
					// console.error('status code:');
					// tslint:disable-next-line:no-debugger
					console.error(error);
					if (error instanceof HttpErrorResponse && [401].includes(error.status)) {
						// auto logout if 401 or 403 response returned from api
						const userData =  JSON.parse(localStorage.getItem("currentuser"));
						if(error.url.includes('/status') && userData && userData['user_info'].user_type === "user" ){
							this.toastr.clear();
							this.toastr.error("Sorry, account not active.", "Oops!", {
								progressBar: true,
								positionClass: "toast-top-right",
								timeOut: 15000,
							});
							this.globalservice.logout(userData['access_token']).then((result) => {
								this.globalservice.terminateUser();						
							}, (err) => {	
								this.globalservice.terminateUser();
							});
						}
					}
					// console.error(error.status);
					// console.error(error.message);
					// console.log('--- end of response---');
				}
			)
		);
	}
}
