import { Injectable } from "@angular/core";
import { ConstantService } from "./constant.service";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { $ } from "protractor";
import { ThrowStmt } from "@angular/compiler";
import { BehaviorSubject, Observable } from "rxjs";
import { LayoutConfigService, SplashScreenService } from "./_base/layout";
import { SplashScreenComponent } from "../views/partials/layout";
import { DatePipe } from '@angular/common';

@Injectable({
	providedIn: "root",
})
export class GlobalService {
	public isLogin: boolean = false;
	public userType;
	public dummyUserPic = "./assets/media/users/default.jpg";
	public userCredit = 0;
	public accountNumber = 0;
	public notificationCount:any;
	public force_resetpass: number = 0;
	public redirectUrl;

	public callerReputationSubject: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(false);
	public callerReputation$: Observable<boolean> =
		this.callerReputationSubject.asObservable();

		public NotificationCountSubject: BehaviorSubject<object> =
		new BehaviorSubject<object>({});

		public NotificationCount$: Observable<object> =
		this.NotificationCountSubject.asObservable();
	constructor(
		public constants: ConstantService,
		private http: HttpClient,
		private router: Router,
		private layoutConfig: LayoutConfigService,
		private splashScreen: SplashScreenService,
		private datePipe: DatePipe
	) {
		if (
			localStorage.getItem("currentuser") != null &&
			localStorage.getItem("currentuser") != "" &&
			localStorage.getItem("currentuser") != "null"
		) {
			var udata = JSON.parse(localStorage.getItem("currentuser"));
			this.userCredit = udata["credit"];
			this.accountNumber = udata["user_info"]["account_no"]
			//this.userCredit = 1;
		}
	}

	checkLogin(email, password,role?:any): Promise<any> {
		var bodyparam;
		if (this.userType == "user") {
			var url = this.constants.API_ENDPOINT + "user/login";
			bodyparam = { username: email, password: password,role:role };
		} else {
			var url = this.constants.API_ENDPOINT + "admin/login";
			bodyparam = {
				username: email,
				password: password,
				user_type: "admin",
			};
		}
		return new Promise((resolve, reject) => {
			const headers = { "Content-Type": "application/json" };
			const body = bodyparam;
			this.http.post(url, body, { headers: headers }).subscribe(
				(res) => {
					resolve(res);
				},
				(err) => {
					reject(err);
				}
			);
		});
	}

	userNameCheck(data): Promise<any> {
		const formData = new FormData();
		formData.append("username", data);
		return new Promise((resolve, reject) => {
			this.http
				.post(
					this.constants.API_ENDPOINT + "user/checkUsername",
					formData,
					{}
				)
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	emailCheck(data): Promise<any> {
		const formData = new FormData();
		formData.append("primaryemail", data);
		return new Promise((resolve, reject) => {
			this.http
				.post(
					this.constants.API_ENDPOINT + "user/checkemail",
					formData,
					{}
				)
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}
	purchasedidadmin(token, type, numbers,area_code,user_id): Promise<any> {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			const body = {
				type: type,
				select_number: numbers,
				email_notification: 1,
				stateoption :area_code,
				user_id:user_id
			};
			this.http
				.post(this.constants.API_ENDPOINT + "did/purchase", body, {
					headers: headers,
				})
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	signupUser(data): Promise<any> {
		const formData = new FormData();
		formData.append("email", data.email);
		formData.append("secondary_email", data.secondary_email);
		formData.append("password", data.password);
		formData.append("username", data.username);
		formData.append("first_name", data.first_name);
		formData.append("last_name", data.last_name);
		formData.append("company", data.company);
		formData.append("address1", data.address1);
		formData.append("address2", data.address2);
		formData.append("city", data.city);
		formData.append("state", data.state);
		formData.append("country", data.country);
		formData.append("zipcode", data.zipcode);
		formData.append("website", data.website);

		formData.append("hdu", data.hdu);
		formData.append("traffic", data.traffic);
		formData.append("referredby", data.referredby);
		formData.append("destinations", data.destinations);
		formData.append("switch", data.switch);
		formData.append("kyc_number", data.kyc_number);
		formData.append("match_percentage", data.match_precentage);
		formData.append("selfie_image", data.selfie_image);
		for (let i = 0; i < data.photoID_type.length; i++) {
			formData.append("photoID_type[]", data.photoID_type[i]);
		}

		for (let i = 0; i < data.photoID.length; i++) {
			formData.append("photoID[]", data.photoID[i]);
		}

		for (let i = 0; i < data.photoID_description.length; i++) {
			formData.append(
				"photoID_description[]",
				data.photoID_description[i]
			);
		}

		for (let i = 0; i < data.companyVerification_type.length; i++) {
			formData.append(
				"companyVerification_type[]",
				data.companyVerification_type[i]
			);
		}

		for (let i = 0; i < data.company_verification_documents.length; i++) {
			formData.append(
				"company_verification_documents[]",
				data.company_verification_documents[i]
			);
		}

		for (let i = 0; i < data.companyVerification_description.length; i++) {
			formData.append(
				"companyVerification_description[]",
				data.companyVerification_description[i]
			);
		}
		formData.append("description", data.description);
		formData.append("phone", data.phone);
		formData.append("mobile", data.mobile);
		formData.append("telemarketing_domain", data.telemarketing_domain);
		return new Promise((resolve, reject) => {
			this.http
				.post(this.constants.API_ENDPOINT + "user/create", formData, {})
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	forgotpassword(email): Promise<any> {
		return new Promise((resolve, reject) => {
			const headers = { "Content-Type": "application/json" };
			const body = { email: email };
			this.http
				.post(
					this.constants.API_ENDPOINT + "user/change-password",
					body,
					{ headers: headers }
				)
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	resetpassword(email, password, cpassword, token): Promise<any> {
		return new Promise((resolve, reject) => {
			const headers = { "Content-Type": "application/json" };
			const body = {
				email: email,
				password: password,
				password_confirmation: cpassword,
				token: token,
			};
			this.http
				.post(
					this.constants.API_ENDPOINT + "user/forgot-password",
					body,
					{ headers: headers }
				)
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	logout(token): Promise<any> {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			this.http
				.get(this.constants.API_ENDPOINT + "user/logout", {
					headers: headers,
				})
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	changeuserStatus(token, id): Promise<any> {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			const body = { id: id };
			this.http
				.post(
					this.constants.API_ENDPOINT + "admin/activate-user",
					body,
					{ headers: headers }
				)
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	changeAvailableToBuyStatus(token, id, purchaseOption): Promise<any> {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			const body = { did: id, purchaseOption: purchaseOption };
			this.http
				.post(
					this.constants.API_ENDPOINT +
						"inventory/Update-Purchase-Option",
					body,
					{ headers: headers }
				)
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	getCustomerById(customerId, token): Promise<any> {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			this.http
				.get(
					this.constants.API_ENDPOINT +
						"admin/customer?id=" +
						customerId,
					{ headers: headers }
				)
				.subscribe(
					(res) => {
						resolve(res["result"]);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	updateCustomer(token, data): Promise<any> {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			const body = data;
			this.http
				.post(
					this.constants.API_ENDPOINT + "admin/edit-customer",
					body,
					{ headers: headers }
				)
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	updateGeneralSetting(token, data): Promise<any> {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			const body = data;
			this.http
				.post(
					this.constants.API_ENDPOINT +
						"admin/update-general-settings",
					body,
					{
						headers: headers,
					}
				)
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	getGeneralSettings(token): Promise<any> {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			this.http
				.get(
					this.constants.API_ENDPOINT + "admin/get-general-setting",
					{ headers: headers }
				)
				.subscribe(
					(res) => {
						resolve(res["result"]);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	activeDeActiveCreditCard(token, id): Promise<any> {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			const body = { card_id: id };
			this.http
				.post(
					this.constants.API_ENDPOINT + "admin/activate-card",
					body,
					{ headers: headers }
				)
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	customerIdwithName(token): Promise<any> {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			this.http
				.get(this.constants.API_ENDPOINT + "admin/active-client", {
					headers: headers,
				})
				.subscribe(
					(res) => {
						resolve(res["result"]);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	addManualPayment(token, data): Promise<any> {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			const body = data;
			this.http
				.post(
					this.constants.API_ENDPOINT + "admin/create-manual-payment",
					body,
					{
						headers: headers,
					}
				)
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	addCharges(token, data): Promise<any> {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			const body = data;
			this.http
				.post(this.constants.API_ENDPOINT + "admin/addCharge", body, {
					headers: headers,
				})
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	addCreditCardPayment(token, data): Promise<any> {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			const body = data;
			this.http
				.post(
					this.constants.API_ENDPOINT +
						"admin/create-creditcard-payment",
					body,
					{
						headers: headers,
					}
				)
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}
	//Subscription

	addSubscription(token, data): Promise<any> {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			const body = data;
			this.http
				.post(
					this.constants.API_ENDPOINT + "subscriptions/modify",
					body,
					{
						headers: headers,
					}
				)
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	//add Caller Reputataion
	addcallerReputation(token, data): Promise<any> {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			const body = data;
			this.http
				.post(
					this.constants.API_ENDPOINT + "admin/refresh-times",
					body,
					{
						headers: headers,
					}
				)
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	//get Caller REputation
	getcallerReputation(token): Promise<any> {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			this.http
				.get(this.constants.API_ENDPOINT + "admin/refresh-times", {
					headers: headers,
				})
				.subscribe(
					(res) => {
						resolve(res["data"]);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	addKycPermission(token, data): Promise<any> {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			const body = data;
			this.http
				.post(
					this.constants.API_ENDPOINT + "admin/manage-kyc-settings",
					body,
					{
						headers: headers,
					}
				)
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	getKycPermission(): Promise<any> {
		return new Promise((resolve, reject) => {
			this.http
				.get(this.constants.API_ENDPOINT + "user/get-kyc-settings")
				.subscribe(
					(res) => {
						resolve(res["data"]);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	getAdminKycPermission(token): Promise<any> {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			this.http
				.get(
					this.constants.API_ENDPOINT +
						"admin/get-kyc-settings-admin",
					{
						headers: headers,
					}
				)
				.subscribe(
					(res) => {
						resolve(res["data"]);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	addCreditCard(token, data): Promise<any> {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			const body = data;
			this.http
				.post(
					this.constants.API_ENDPOINT + "payments/credit-card",
					body,
					{ headers: headers }
				)
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	terminateUser() {
		localStorage.clear();
		this.isLogin = false;
		// if(this.userType == 'user'){
		this.router.navigate(["/login"]);
		// }else{
		// 	this.router.navigate(['/admin']);
		// }
	}

	getSipCredentials(token): Promise<any> {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			this.http
				.get(
					this.constants.API_ENDPOINT + "devices/get-sip-credentials",
					{ headers: headers }
				)
				.subscribe(
					(res) => {
						resolve(res["result"]);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	getDidList(token): Promise<any> {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			this.http
				.get(
					this.constants.API_ENDPOINT + "user/didlist",
					{ headers: headers }
				)
				.subscribe(
					(res) => {
						resolve(res["result"]);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}


	getwhiteListedIps(token): Promise<any> {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			this.http
				.get(this.constants.API_ENDPOINT + "devices/list-hosts", {
					headers: headers,
				})
				.subscribe(
					(res) => {
						if (res != null) {
							resolve(res["result"]);
						}
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	addhost(token, ipaddress, port): Promise<any> {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			const body = { host: ipaddress, port: port };
			this.http
				.post(this.constants.API_ENDPOINT + "devices/add-host", body, {
					headers: headers,
				})
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	removehost(token, host): Promise<any> {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			const body = { host: host };
			this.http
				.post(
					this.constants.API_ENDPOINT + "devices/delete-host",
					body,
					{ headers: headers }
				)
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	chngAuthType(token, type): Promise<any> {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			const body = { auth_type: type };
			this.http
				.post(
					this.constants.API_ENDPOINT + "devices/change-auth-type",
					body,
					{ headers: headers }
				)
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	getdid(token: any, payload: any): Promise<any> {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};

			this.http
				.post(
					this.constants.API_ENDPOINT + "did/search_new_inventory",
					payload,
					{
						headers: headers,
					}
				)
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}
	purchasedid(token, type, numbers,area_code): Promise<any> {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			const body = {
				type: type,
				select_number: numbers,
				email_notification: 1,
				stateoption :area_code
			};
			this.http
				.post(this.constants.API_ENDPOINT + "did/purchase", body, {
					headers: headers,
				})
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	getsingledidadmin(token: any, payload: any): Promise<any> {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};

			this.http
				.post(
					this.constants.API_ENDPOINT + "did/admin_search",
					payload,
					{
						headers: headers,
					}
				)
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	purchasedidCheck(token, type, numbers, area_match,user_id?): Promise<any> {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			const body = {
				user_id:user_id,
				type: type,
				number_count: numbers,
				area_match: area_match
			};
			this.http
				.post(
					this.constants.API_ENDPOINT + "did/check-credit-fund",
					body,
					{
						headers: headers,
					}
				)
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	releaseDid(token, didnum, mode): Promise<any> {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			const body = { did: didnum, mode: mode, email_notification: 1 };
			this.http
				.post(this.constants.API_ENDPOINT + "did/release", body, {
					headers: headers,
				})
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	releaseDidByAdmin(token, didnum, cardId, release_type): Promise<any> {
		return new Promise((resolve, reject) => {
			var data = [
				{
					did: didnum,
					cardId: cardId,
					release_type: release_type,
					email_notification: 1,
				},
			];
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			const body = data;
			this.http
				.post(this.constants.API_ENDPOINT + "admin/did/release", body, {
					headers: headers,
				})
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	releaseDidByAdminInventory(
		token,
		didnum,
		cardId,
		release_type
	): Promise<any> {
		return new Promise((resolve, reject) => {
			var data = [
				{ did: didnum, cardId: cardId, release_type: release_type },
			];
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			const body = data;
			this.http
				.post(
					this.constants.API_ENDPOINT + "admin/inventory/release",
					body,
					{
						headers: headers,
					}
				)
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	releaseDidMultipleByAdmin(
		token,
		didnum,
		cardId,
		release_type,
		mode
	): Promise<any> {
		return new Promise((resolve, reject) => {
			didnum = didnum.split(",");
			cardId = cardId.split(",");
			var data = [];
			for (var i = 0; i < didnum.length; i++) {
				data.push({
					did: didnum[i],
					cardId: cardId[i],
					release_type: release_type,
					mode: mode,
					email_notification: 1,
				});
			}
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			const body = data;
			this.http
				.post(this.constants.API_ENDPOINT + "admin/did/release", body, {
					headers: headers,
				})
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	assignDidSubmit(token, user_id, records): Promise<any> {
		return new Promise((resolve, reject) => {
			var data = { user_id: user_id, records: records };
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			const body = data;
			this.http
				.post(
					this.constants.API_ENDPOINT + "inventory/assignDids",
					body,
					{
						headers: headers,
					}
				)
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	releaseDidMultipleByAdminInventory(
		token,
		didnum,
		cardId,
		release_type,
		mode
	): Promise<any> {
		return new Promise((resolve, reject) => {
			didnum = didnum.split(",");
			cardId = cardId.split(",");
			var data = [];
			for (var i = 0; i < didnum.length; i++) {
				data.push({
					did: didnum[i],
					cardId: cardId[i],
					release_type: release_type,
					mode: mode,
				});
			}
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			const body = data;
			this.http
				.post(
					this.constants.API_ENDPOINT + "admin/inventory/release",
					body,
					{
						headers: headers,
					}
				)
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	updatemodify(
		token,
		destinationType,
		destinationValue,
		description,
		didno,
		id,
		mode
	): Promise<any> {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			const body = {
				dtype: destinationType,
				dval: destinationValue,
				desc: description,
				didno: didno,
				id: id,
				mode: mode,
			};
			this.http
				.post(this.constants.API_ENDPOINT + "did/modify", body, {
					headers: headers,
				})
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	getCustomersNumber(token): Promise<any> {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			this.http
				.get(this.constants.API_ENDPOINT + "did/cusomersNumOnly", {
					headers: headers,
				})
				.subscribe(
					(res) => {
						resolve(res["result"]);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	sendSMS(token, sourceNum, destNum, message): Promise<any> {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			const body = { from: sourceNum, to: destNum, msg: message };
			this.http
				.post(this.constants.API_ENDPOINT + "sms/send", body, {
					headers: headers,
				})
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	getUserCards(token): Promise<any> {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			this.http
				.get(this.constants.API_ENDPOINT + "admin/get-user-own-card", {
					headers: headers,
				})
				.subscribe(
					(res) => {
						resolve(res["result"]);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	creditCardPaymentbyUser(token, data): Promise<any> {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			const body = data;
			this.http
				.post(
					this.constants.API_ENDPOINT +
						"admin/creditcard-payment-byuser",
					body,
					{
						headers: headers,
					}
				)
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	checkuser(token): Promise<any> {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			this.http
				.get(this.constants.API_ENDPOINT + "status", {
					headers: headers,
				})
				.subscribe(
					(res) => {


						 this.NotificationCountSubject.next(res)

						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	updatenotificationSetting(token, status, threshold, email): Promise<any> {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			const body = {
				notification_status: status,
				notification_threshold: threshold,
				notification_email: email,
			};
			this.http
				.post(
					this.constants.API_ENDPOINT +
						"admin/update-notification-setting",
					body,
					{
						headers: headers,
					}
				)
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	updateAutoRecharge(
		token,
		threshold,
		amount,
		cardid,
		emails,
		status
	): Promise<any> {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			const body = {
				threshold: threshold,
				amount: amount,
				cardid: cardid,
				emails: emails,
				status: status,
			};
			this.http
				.post(
					this.constants.API_ENDPOINT + "admin/update-auto-recharge",
					body,
					{
						headers: headers,
					}
				)
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	updateUserCredit(credit) {
		// if(credit != null){
		let udata = JSON.parse(localStorage.getItem("currentuser"));
		udata["credit"] = credit;
		this.userCredit = credit;
		localStorage.setItem("currentuser", JSON.stringify(udata));
		// }
	}

	activeDeActiveEmailTemplate(token, id) {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			const body = {};
			this.http
				.post(
					this.constants.API_ENDPOINT +
						"admin/status-email-template?id=" +
						id,
					body,
					{
						headers: headers,
					}
				)
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	getEmailTemplateById(token, E_T_id) {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			const body = {};
			this.http
				.get(
					this.constants.API_ENDPOINT +
						"admin/edit-email-template?id=" +
						E_T_id,
					{
						headers: headers,
					}
				)
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	updateEmailTemplate(token, name, subject, content, id) {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			const body = {
				name: name,
				subject: subject,
				content: content,
				status: status,
				id: id,
			};
			this.http
				.post(
					this.constants.API_ENDPOINT + "admin/update-email-template",
					body,
					{
						headers: headers,
					}
				)
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	getMyrates(token) {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			const body = {};
			this.http
				.get(this.constants.API_ENDPOINT + "/get-rates", {
					headers: headers,
				})
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	getRatingAndCallinfo(token) {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			const body = {};
			this.http
				.get(this.constants.API_ENDPOINT + "get-count-ratecalls", {
					headers: headers,
				})
				.subscribe(
					(res) => {
						resolve(res);
						if (res == null) {
							this.terminateUser();
						}
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	getChartRating(token, selectdays, expenditure) {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			const body = { selectdays: +selectdays, expenditure: expenditure };
			this.http
				.post(this.constants.API_ENDPOINT + "getRateByDays", body, {
					headers: headers,
				})
				.subscribe(
					(res) => {
						if (res == null) {
							this.terminateUser();
						}
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	importDID(token, customer, records) {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			const body = { customer: customer, records: records };
			this.http
				.post(this.constants.API_ENDPOINT + "admin/importDID", body, {
					headers: headers,
				})
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	import(token, records) {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			const body = { records: records };
			this.http
				.post(
					this.constants.API_ENDPOINT + "inventory/importDID",
					body,
					{
						headers: headers,
					}
				)
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	getDailyusagereport(token, currentdate, nextdate) {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			var body = { start_date:  this.datePipe.transform(currentdate, 'yyyy-MM-dd'), end_date: this.datePipe.transform(nextdate, 'yyyy-MM-dd') };
			this.http
				.post(
					this.constants.API_ENDPOINT + "reports/daily-usage",
					body,
					{ headers: headers }
				)
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	createToken(token, body) {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			this.http
				.post(
					this.constants.API_ENDPOINT + "support/create-ticket",
					body,
					{ headers: headers }
				)
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}
	deleteCard(token, cardId): Promise<any> {
		return new Promise((resolve, reject) => {
			var data = { id: cardId };
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			const body = data;
			this.http
				.post(this.constants.API_ENDPOINT + "admin/delete-card", body, {
					headers: headers,
				})
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	saveWhitelist(token, ip_address, description): Promise<any> {
		return new Promise((resolve, reject) => {
			var data = { ip_address: ip_address, description: description };
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			const body = data;
			this.http
				.post(
					this.constants.API_ENDPOINT + "admin/save-whitelist",
					body,
					{ headers: headers }
				)
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	deleteWhitelistIp(token, id): Promise<any> {
		return new Promise((resolve, reject) => {
			var data = { id: id };
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			const body = data;
			this.http
				.post(
					this.constants.API_ENDPOINT + "admin/delete-whitelist-ips",
					body,
					{
						headers: headers,
					}
				)
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	updateWhitelistIp(token, ip_address, id, description): Promise<any> {
		return new Promise((resolve, reject) => {
			var data = {
				ip_address: ip_address,
				id: id,
				description: description,
			};
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			const body = data;
			this.http
				.post(
					this.constants.API_ENDPOINT + "admin/save-whitelist",
					body,
					{ headers: headers }
				)
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	saveWhitelistUrl(token, ip_address, description): Promise<any> {
		return new Promise((resolve, reject) => {
			var data = { url: ip_address, description: description };
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			const body = data;
			this.http
				.post(
					this.constants.API_ENDPOINT + "admin/save-whitelist-url",
					body,
					{ headers: headers }
				)
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	deleteWhitelistUrl(token, id): Promise<any> {
		return new Promise((resolve, reject) => {
			var data = { id: id };
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			const body = data;
			this.http
				.post(
					this.constants.API_ENDPOINT + "admin/delete-whitelist-url",
					body,
					{
						headers: headers,
					}
				)
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	updateWhitelistUrl(token, ip_address, description, id): Promise<any> {
		return new Promise((resolve, reject) => {
			var data = { url: ip_address, id: id, description: description };
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			const body = data;
			this.http
				.post(
					this.constants.API_ENDPOINT + "admin/save-whitelist-url",
					body,
					{ headers: headers }
				)
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	addCallerId(token, data): Promise<any> {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			const body = data;
			this.http
				.post(
					this.constants.API_ENDPOINT + "devices/update-callerid",
					body,
					{ headers: headers }
				)
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	addInbound(token, data): Promise<any> {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			const body = data;
			this.http
				.post(
					this.constants.API_ENDPOINT + "devices/saveInboundData",
					body,
					{ headers: headers }
				)
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	saveNotifications(token, data): Promise<any> {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			const body = data;
			this.http
				.post(
					this.constants.API_ENDPOINT + "admin/save-messages",
					body,
					{ headers: headers }
				)
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	changeNotificationStatus(token, data): Promise<any> {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			const body = data;
			this.http
				.post(
					this.constants.API_ENDPOINT +
						"admin/status-change-messages",
					body,
					{
						headers: headers,
					}
				)
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	deleteNotification(token, id): Promise<any> {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			const body = { id: id };
			this.http
				.post(
					this.constants.API_ENDPOINT + "admin/delete-messages",
					body,
					{ headers: headers }
				)
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}
	//subscription delete
	deleteSubscription(token, id): Promise<any> {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			const body = { id: id };
			this.http
				.post(
					this.constants.API_ENDPOINT + "subscriptions/delete",
					body,
					{ headers: headers }
				)
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	//subscription cancel
	cancelSubscription(token, id): Promise<any> {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			const body = { id: id };
			this.http
				.post(
					this.constants.API_ENDPOINT + "subscriptions/cancel",
					body,
					{ headers: headers }
				)
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	getNotificationById(token, id): Promise<any> {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			const body = { id: id };
			this.http
				.post(
					this.constants.API_ENDPOINT + "admin/message-byid",
					body,
					{ headers: headers }
				)
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	disableNotification(token, status, messageId): Promise<any> {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			const body = { status: status, id: messageId };
			this.http
				.post(
					this.constants.API_ENDPOINT +
						"user/set-notification-status",
					body,
					{
						headers: headers,
					}
				)
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	bulkDisconnectDID(token, body) {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			this.http
				.post(
					this.constants.API_ENDPOINT + "admin/bulk-disconnect",
					body,
					{
						headers: headers,
					}
				)
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	didDisconnect(token, body) {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			this.http
				.post(
					this.constants.API_ENDPOINT + "did/didDisconnect",
					body,
					{
						headers: headers,
					}
				)
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	didDisconnectDetails(token, body){
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			this.http
				.post(
					this.constants.API_ENDPOINT + "did/disconnectDetails",
					body,
					{
						headers: headers,
					}
				)
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	postDocument(token, payload) {
		const formData = new FormData();
		formData.append("document", payload.document);
		formData.append("type", payload.type);
		formData.append("description", payload.description);
		formData.append("user_id", payload.user_id);

		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
			};
			this.http
				.post(
					this.constants.API_ENDPOINT + "admin/documents-save",
					formData,
					{
						headers: headers,
					}
				)
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	download(token, url: string) {
		return new Promise((resolve, reject) => {
			// const headers = {
			// 	Authorization: `bearer ${token}`,
			// };
			this.http
				.get(url, {
					// headers : headers,
					responseType: "blob",
				})
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	deleteDocument(token, fileId): Promise<any> {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			this.http
				.post(
					this.constants.API_ENDPOINT + "admin/documents-delete",
					{ id: fileId },
					{
						headers: headers,
					}
				)
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	verifyBulkDidPurchase(token, userId, records: []) {
		return new Promise((resolve, reject) => {
			const headers = new HttpHeaders()
				.set("Authorization", `bearer ${token}`)
				.set("Content-Type", "application/json");

			// const body = JSON.stringify({ userId: userId, 'records[]': records });
			const body = { user_id: userId, records: records };
			this.http
				.post(
					this.constants.API_ENDPOINT + "did/verify-purchase",
					body,
					{
						headers: headers,
					}
				)
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	verifyBulkDidPurchaseNew(token, userId, records: [], type, area_match?) {
		return new Promise((resolve, reject) => {
			const headers = new HttpHeaders()
				.set("Authorization", `bearer ${token}`)
				.set("Content-Type", "application/json");

			// const body = JSON.stringify({ userId: userId, 'records[]': records });
			const body = {
				user_id: userId,
				records: records,
				type: type,
				area_match: area_match,
			};
			this.http
				.post(
					this.constants.API_ENDPOINT +
						"did/verify-purchase-new_inventory",
					body,
					{
						headers: headers,
					}
				)
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	didOperations(token, did_numbers: [], method, type) {
		return new Promise((resolve, reject) => {
			const headers = new HttpHeaders()
				.set("Authorization", `bearer ${token}`)
				.set("Content-Type", "application/json");

			// const body = JSON.stringify({ userId: userId, 'records[]': records });
			const body = {
				did_numbers: did_numbers,
				method: method,
				type: type,
			};
			this.http
				.post(
					this.constants.API_ENDPOINT +
						"admin/bulk-disconnect-inventory",
					body,
					{
						headers: headers,
					}
				)
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	confirmBulkDidPurchase(token, userId, uniqueId, type) {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			const body = {
				user_id: userId,
				unique_id: uniqueId,
				type: type,
				email_notification: 1,
			};
			this.http
				.post(this.constants.API_ENDPOINT + "did/bulk-purchase", body, {
					headers: headers,
				})
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	confirmBulkDidPurchaseExport(token, userId) {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			const body = { user_id: userId };
			this.http
				.post(this.constants.API_ENDPOINT + "did/bulk-purchase", body, {
					headers: headers,
				})
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	addTaxSurcharge(token, data) {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};

			this.http
				.post(this.constants.API_ENDPOINT + "taxes/save", data, {
					headers: headers,
				})
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	deleteTaxSurcharge(token, id): Promise<any> {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			const body = { id: id };
			this.http
				.post(this.constants.API_ENDPOINT + "taxes/delete", body, {
					headers: headers,
				})
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	SubscribeCallerReputation(token): Promise<any> {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			this.http
				.post(
					this.constants.API_ENDPOINT +
						"user/reputation-subscription",
					null,
					{
						headers: headers,
					}
				)
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	refreshTimes(token): Promise<any> {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			this.http
				.post(
					this.constants.API_ENDPOINT + "admin/refresh-times",
					null,
					{ headers: headers }
				)
				.subscribe(
					(res) => {
						resolve(res);
						this.callerReputationSubject.next(true);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	getCallerReputationDashboardGraphData(token): Promise<any> {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};

			this.http
				.get(this.constants.API_ENDPOINT + "user/get-dashboard-graph", {
					headers: headers,
				})
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	getCallerReputationTextValues(token): Promise<any> {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};

			this.http
				.get(this.constants.API_ENDPOINT + "user/get-initial-rates", {
					headers: headers,
				})
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	CheckCallerReputationStatus(token): Promise<any> {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};

			this.http
				.get(
					this.constants.API_ENDPOINT + "user/check-cr-subscription",
					{
						headers: headers,
					}
				)
				.subscribe(
					(res: any) => {
						if (res.status) {
							this.callerReputationSubject.next(true);
						} else {
							this.callerReputationSubject.next(false);
						}
						localStorage.setItem(
							"callerReputationStatus",
							res.status
						);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	// getRetentionPeriod(token): Promise<any> {
	// 	return new Promise((resolve, reject) => {
	// 		const headers = {
	// 			Authorization: `bearer ${token}`,
	// 			'Content-Type': 'application/json',
	// 		};
	// 		// const body = data;
	// 		this.http
	// 			.post(this.constants.API_ENDPOINT + 'did/get-retention-period', {
	// 				headers: headers,
	// 			})
	// 			.subscribe(
	// 				(res) => {
	// 					resolve(res);
	// 				},
	// 				(err) => {
	// 					reject(err);
	// 				}
	// 			);
	// 	});
	// }
	getRetentionPeriod(token): Promise<any> {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			const body = "";
			this.http;
			return this.http
				.get<any>(
					this.constants.API_ENDPOINT + "did/get-retention-period",
					{ headers: headers }
				)
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}
	getRetentionPeriodCustomer(token, customerid): Promise<any> {
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			const body = { customerid: customerid };
			this.http;
			return this.http
				.get<any>(
					this.constants.API_ENDPOINT +
						`did/get-retention-period?customerid=${customerid}`,
					{ headers: headers }
				)
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	getAllStates(): Observable<any> {
		const endPoint = "user/get-states";
		return this.http.get<any>(this.constants.API_ENDPOINT + endPoint);
	}

	didDestinationUpdate(token, payload) {

		console.log("didDestinationUpdate",payload)
		return new Promise((resolve, reject) => {
			const headers = {
				Authorization: `bearer ${token}`,
				"Content-Type": "application/json",
			};
			const body = {
				account_no:payload.account_no,
				destination:payload.destination,
				select_number:payload.type == 2 ? payload.dids : null,
				type:payload.type == 1 ? 'all' : 'custom',
				destinationType:payload.destinationType?payload.destinationType:""
			}
			this.http
				.post(this.constants.API_ENDPOINT + "did/destination-update", body, {
					headers: headers,
				})


				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	getCarrierList(token): Observable<any> {

		const headers = {
			Authorization: `bearer ${token}`,
			"Content-Type": "application/json",
		};
		return this.http
			.get(this.constants.API_ENDPOINT + "admin/carrierlist", {
				headers: headers,
			})
	}
}
