// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
// RxJS
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Auth
import { AuthNoticeService, AuthService } from '../../../../core/auth';
import { MustMatch } from '../../../../helper/must-match.validator';
import { GlobalService } from '../../../../core/global.service';
import { ToastrService } from 'ngx-toastr';
import { PasswordStrengthValidator } from './password-strength';

@Component({
  selector: 'kt-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
	public token;
	public email;

  // Public params
	resetPasswordForm: FormGroup;
	loading = false;
  errors: any = [];
  private unsubscribe: Subject<any>;

  constructor(
		private authService: AuthService,
		public authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private router: Router,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private route: ActivatedRoute,
		private globalservice: GlobalService,
		public toastr: ToastrService
	) {
		this.unsubscribe = new Subject();
	}

  ngOnInit() {
	this.route.params.forEach((urlParameters) => {
	  this.token = urlParameters['token'];
      this.email = urlParameters['email'];
	});
	this.initchangepasswordForm();

  }

  ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
  }

  initchangepasswordForm() {
		this.resetPasswordForm = this.fb.group({
			email: [this.email, Validators.compose([
				Validators.required,
				Validators.email
			])
      ],
      password: ['', Validators.compose([
				Validators.required,
				PasswordStrengthValidator.passwordStrength
			])
			],
			confirmPassword: ['', Validators.compose([
				Validators.required,
			])
			]
		},{
            validator: MustMatch('password', 'confirmPassword')
        });
  }

  submit() {
	  	const controls = this.resetPasswordForm.controls;
		/** check form */
		if (this.resetPasswordForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}


		var email = controls['email'].value;
		var password = controls['password'].value;
		var cpassword = controls['confirmPassword'].value;

		this.globalservice.resetpassword(email,password,cpassword,this.token).then((result) => {
			this.toastr.success(result.message, 'Success!', {
				progressBar: true,
				positionClass : 'toast-top-right',
				timeOut:15000
			});
			this.router.navigate(['/login']);
			}, (err) => {
			this.toastr.error(err.error.message, 'Oops!', {
				progressBar: true,
				positionClass : 'toast-top-right',
				timeOut:15000
			});
		});

  }

  isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.resetPasswordForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result =
			control.hasError(validationType) &&
			(control.dirty || control.touched);
		return result;
	}

	

}
