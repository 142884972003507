// Angular
import {
	AfterViewInit,
	Component,
	ElementRef,
	OnInit,
	ViewChild,
	ChangeDetectionStrategy,
} from "@angular/core";
import {
	NavigationCancel,
	NavigationEnd,
	NavigationStart,
	RouteConfigLoadEnd,
	RouteConfigLoadStart,
	Router,
} from "@angular/router";
// Object-Path
import * as objectPath from "object-path";
// Loading bar
import { LoadingBarService } from "@ngx-loading-bar/core";
// Layout
import {
	LayoutConfigService,
	LayoutRefService,
} from "../../../core/_base/layout";
// HTML Class Service
import { HtmlClassService } from "../html-class.service";
import { GlobalService } from "../../../core/global.service";
import Swal from "sweetalert2";

@Component({
	selector: "kt-header",
	templateUrl: "./header.component.html",
	styleUrls: ["./header.component.scss"],

	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, AfterViewInit {
	// Public properties
	menuHeaderDisplay: boolean;
	fluid: boolean;
	public last_user;
	public udata;
	public switchPanelHdr;
	public EnabledAlertMessages: any;
	public EnabledPopupMessages: any;
	public token;
	pouparr = [];
	public customerId;

	@ViewChild("ktHeader", { static: true }) ktHeader: ElementRef;

	/**
	 * Component constructor
	 *
	 * @param router: Router
	 * @param layoutRefService: LayoutRefService
	 * @param layoutConfigService: LayoutConfigService
	 * @param loader: LoadingBarService
	 * @param htmlClassService: HtmlClassService
	 */
	constructor(
		private router: Router,
		private layoutRefService: LayoutRefService,
		private layoutConfigService: LayoutConfigService,
		public loader: LoadingBarService,
		public htmlClassService: HtmlClassService,
		public globalservice: GlobalService
	) {
		this.udata = JSON.parse(localStorage.getItem("currentuser"));
		this.customerId = this.udata["user_info"]["id"];
		this.token = this.udata["access_token"];
		if (localStorage.getItem("checkSwitchPanel")) {
			this.switchPanelHdr = localStorage.getItem("checkSwitchPanel");
		}
		if (this.udata["user_info"]["user_type"] == "user") {
			if (this.udata["EnabledAlertMessages"]) {
				this.EnabledAlertMessages = this.udata["EnabledAlertMessages"];
			}
		}

		// page progress bar percentage
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationStart) {
				// set page progress bar loading to start on NavigationStart event router
				this.loader.start();
			}
			if (event instanceof RouteConfigLoadStart) {
				this.loader.increment(35);
			}
			if (event instanceof RouteConfigLoadEnd) {
				this.loader.increment(75);
			}
			if (
				event instanceof NavigationEnd ||
				event instanceof NavigationCancel
			) {
				// set page progress bar loading to end on NavigationEnd event router
				this.loader.complete();
			}
		});

		/* if(localStorage.getItem('switchPanel')){
			this.switchPanel = localStorage.getItem('switchPanel');
		} */
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		const config = this.layoutConfigService.getConfig();
		if (this.udata && this.udata["user_info"]["user_type"] == "user") {
			if (this.udata["EnabledPopupMessages"]) {
				this.EnabledPopupMessages = this.udata["EnabledPopupMessages"];
				for (const message of this.EnabledPopupMessages) {
					if (message["status"] == 1) {
						setTimeout(() => {
							let messageId = message["id"];
							this.pouparr.push({
								message: message["message"],
								id: messageId,
							});

							this.popupNotification(this.pouparr[0]);
						}, 2000);
					}
				}
			}
		}

		// get menu header display option
		this.menuHeaderDisplay = objectPath.get(
			config,
			"header.menu.self.display"
		);

		// header width fluid
		this.fluid = objectPath.get(config, "header.self.width") === "fluid";

		// animate the header minimize the height on scroll down
		if (
			objectPath.get(config, "header.self.fixed.desktop.enabled") ||
			objectPath.get(config, "header.self.fixed.desktop")
		) {
			// header minimize on scroll down
			this.ktHeader.nativeElement.setAttribute(
				"data-ktheader-minimize",
				"1"
			);
		}
	}

	ngAfterViewInit(): void {
		// keep header element in the service
		this.layoutRefService.addElement("header", this.ktHeader.nativeElement);
	}

	switchClick() {
		this.last_user = JSON.parse(localStorage.getItem("last_user"));
		if (this.last_user) {
			this.router.navigate([
				"/admin/impersonate/" + this.last_user + "/admin",
			]);
		} else {
			this.router.navigate(["/admin/impersonate/admin/admin"]);
		}
	}

	popupNotification(message) {
		Swal.fire({
			title: "",
			html: message.message,
			showCancelButton: true,
			confirmButtonText: "Do not show",
			confirmButtonColor: "red",
			cancelButtonText: "Continue",
			cancelButtonColor: "green",
		}).then((result) => {
			if (result.value) {
				var status = 1;
				var messageId = message.id;
				this.globalservice
					.disableNotification(this.token, status, messageId)
					.then(
						(result) => {
							if (result.status) {
								for (
									let index = 0;
									index <
									this.udata["EnabledPopupMessages"].length;
									index++
								) {
									if (
										this.udata["EnabledPopupMessages"][
											index
										]["message"] == message.message
									) {
										this.udata["EnabledPopupMessages"][
											index
										]["status"] = 0;
									}
									localStorage.setItem(
										"currentuser",
										JSON.stringify(this.udata)
									);
								}
							}
							this.pouparr.shift();
							if (this.pouparr.length > 0) {
								this.popupNotification(this.pouparr[0]);
							}
						},
						(err) => {
							if (err.error.status === "Token is Expired") {
								this.globalservice.terminateUser();
								return;
							}
							if (err.error.status == "login_attempt_exceed") {
								this.globalservice.terminateUser();
								return;
							}
						}
					);
			} else {
				this.pouparr.shift();
				for (
					let index = 0;
					index < this.udata["EnabledPopupMessages"].length;
					index++
				) {
					if (
						this.udata["EnabledPopupMessages"][index]["message"] ==
						message.message
					) {
						this.udata["EnabledPopupMessages"][index]["status"] = 0;
					}
					localStorage.setItem(
						"currentuser",
						JSON.stringify(this.udata)
					);
				}
				if (this.pouparr.length > 0) {
					this.popupNotification(this.pouparr[0]);
				}
			}
		});
	}
}
