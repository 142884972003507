// Angular
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
// Material
import {
	MatButtonModule,
	MatCheckboxModule,
	MatFormFieldModule,
	MatIconModule,
	MatInputModule,
	MatTableModule,
	MatTooltipModule,
} from '@angular/material';
// Translate
import { TranslateModule } from '@ngx-translate/core';
// NGRX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
// CRUD
import { InterceptService } from '../../../core/_base/crud/';
// Module components
import { customerComponent } from './customer.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { AuthNoticeComponent } from './auth-notice/auth-notice.component';
import { MatSelectModule } from '@angular/material/select';
// Auth
import { AuthEffects, AuthGuard, authReducer, AuthService } from '../../../core/auth';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { RecaptchaModule } from 'angular-google-recaptcha';

const routes: Routes = [
	{
		path: '',
		component: customerComponent,
		children: [
			{
				path: '',
				redirectTo: 'login',
				pathMatch: 'full',
			},
			{
				path: 'login',
				component: LoginComponent,
				data: { returnUrl: window.location.pathname },
			},
			{
				path: 'customer/register',
				component: RegisterComponent,
			},
			{
				path: 'customer/forgot-password',
				component: ForgotPasswordComponent,
			},
			{
				path: 'customer/reset-password/:token/:email',
				component: ResetPasswordComponent,
			},
		],
	},
];

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MatButtonModule,
		RouterModule.forChild(routes),
		MatInputModule,
		MatFormFieldModule,
		MatSelectModule,
		MatIconModule,
		MatTableModule,
		MatCheckboxModule,
		MatTooltipModule,
		TranslateModule.forChild(),
		StoreModule.forFeature('auth', authReducer),
		EffectsModule.forFeature([AuthEffects]),
		RecaptchaModule.forRoot({
			siteKey: '6LfmSMsUAAAAAMoSl3UwBWVApsRoFtcN4ck64q-z',
		}),
	],
	providers: [
		InterceptService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: InterceptService,
			multi: true,
		},
	],
	exports: [customerComponent],
	declarations: [
		customerComponent,
		LoginComponent,
		RegisterComponent,
		ForgotPasswordComponent,
		AuthNoticeComponent,
		ResetPasswordComponent,
	],
})
export class customerModule {
	static forRoot(): ModuleWithProviders {
		return {
			ngModule: customerModule,
			providers: [AuthService, AuthGuard],
		};
	}
}
