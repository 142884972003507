// Angular
import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as $ from 'jquery';
/**
 * Sample context menu dropdown
 */
@Component({
	selector: 'kt-context-menu',
	templateUrl: './context-menu.component.html',
	styleUrls: ['./context-menu.component.scss']
})
export class ContextMenuComponent {
	@Input() product: any;
	@Output("invokeModal") invokeModal: EventEmitter<any> = new EventEmitter();

	openCreditModal(dropdownElem){
		dropdownElem.close();
		this.invokeModal.emit(this.product);
	}

}