// Angular
import {
	Component,
	ElementRef,
	OnInit,
	Renderer2,
	ViewEncapsulation,
} from "@angular/core";
// Layout
import {
	LayoutConfigService,
	SplashScreenService,
	TranslationService,
} from "../../../core/_base/layout";
// Auth
import { AuthNoticeService } from "../../../core/auth";
import {
	ActivatedRoute,
	Router,
	NavigationEnd,
	NavigationStart,
} from "@angular/router";
import { filter } from "rxjs/operators";

@Component({
	selector: "kt-customer",
	templateUrl: "./customer.component.html",
	styleUrls: ["./customer.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class customerComponent implements OnInit {
	// Public properties
	today: number = Date.now();
	headerLogo: string;
	public showextraheader: boolean = true;

	/**
	 * Component constructor
	 *
	 * @param el
	 * @param render
	 * @param layoutConfigService: LayoutConfigService
	 * @param authNoticeService: authNoticeService
	 * @param translationService: TranslationService
	 * @param splashScreenService: SplashScreenService
	 */
	constructor(
		private el: ElementRef,
		private render: Renderer2,
		private layoutConfigService: LayoutConfigService,
		public authNoticeService: AuthNoticeService,
		private translationService: TranslationService,
		private splashScreenService: SplashScreenService,
		private router: Router,
		private route: ActivatedRoute
	) {}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		this.translationService.setLanguage(
			this.translationService.getSelectedLanguage()
		);
		this.headerLogo = this.layoutConfigService.getLogo();

		this.splashScreenService.hide();

		if (
			localStorage.getItem("currentuser") != null &&
			localStorage.getItem("currentuser") != "" &&
			localStorage.getItem("currentuser") != "null"
		) {
			var udata = JSON.parse(localStorage.getItem("currentuser"));
			if (udata["user_info"]["user_type"] === "admin") {
				location.href = "/admin/dashboard";
			} else {
				location.href = "/dashboard";
			}
		}

		this.router.events
			.pipe(filter((event: any) => event instanceof NavigationStart))
			.subscribe((event) => {
				if (event.url == "/customer/register") {
					this.showextraheader = false;
				} else {
					this.showextraheader = true;
				}
			});

		if (this.router.url == "/customer/register") {
			this.showextraheader = false;
		}
	}

	/**
	 * Load CSS for this specific page only, and destroy when navigate away
	 * @param styleUrl
	 */
	private loadCSS(styleUrl: string) {
		return new Promise((resolve, reject) => {
			const styleElement = document.createElement("link");
			styleElement.href = styleUrl;
			styleElement.type = "text/css";
			styleElement.rel = "stylesheet";
			styleElement.onload = resolve;
			this.render.appendChild(this.el.nativeElement, styleElement);
		});
	}
}
