// Angular
import {
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	ViewEncapsulation,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
// RxJS
import { Observable, Subject, from } from "rxjs";
import { finalize, takeUntil, tap } from "rxjs/operators";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Store
import { Store } from "@ngrx/store";
import { AppState } from "../../../../core/reducers";
// Auth
import { AuthNoticeService, AuthService, Login } from "../../../../core/auth";
import { GlobalService } from "../../../../core/global.service";
import { ToastrService } from "ngx-toastr";
import { Location } from "@angular/common";

/**
 * ! Just example => Should be removed in development
 */
const DEMO_PARAMS = {
	EMAIL: "admin@demo.com",
	PASSWORD: "demo",
};

@Component({
	selector: "kt-login",
	templateUrl: "./login.component.html",
	encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit, OnDestroy {
	// Public params
	loginForm: FormGroup;
	public loading = false;
	isLoggedIn$: Observable<boolean>;
	errors: any = [];
	myRecaptcha: boolean;

	private unsubscribe: Subject<any>;

	private returnUrl: any;

	// Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	/**
	 * Component constructor
	 *
	 * @param router: Router
	 * @param auth: AuthService
	 * @param authNoticeService: AuthNoticeService
	 * @param translate: TranslateService
	 * @param store: Store<AppState>
	 * @param fb: FormBuilder
	 * @param cdr
	 * @param route:ActivatedRoute
	 */
	constructor(
		private router: Router,
		private auth: AuthService,
		private authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private store: Store<AppState>,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private route: ActivatedRoute,
		private globalservice: GlobalService,
		public toastr: ToastrService,
		public location: Location
	) {
		this.unsubscribe = new Subject();
		this.globalservice.userType = "user";
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		this.initLoginForm();
		// redirect back to the returnUrl before login
		this.route.queryParams.subscribe((params) => {
			this.returnUrl = params["returnUrl"] || "/";
		});

		if (
			localStorage.getItem("currentuser") != null &&
			localStorage.getItem("currentuser") != "" &&
			localStorage.getItem("currentuser") != "null" &&
			!this.loginForm.controls["myRecaptcha"].value
		) {
			var udata = JSON.parse(localStorage.getItem("currentuser"));
			if (udata["user_info"]["user_type"] === "admin") {
				location.href = "/admin/dashboard";
			} else {
				location.href = "/dashboard";
			}
		}
	}

	onScriptLoad() {
		console.log("Google reCAPTCHA loaded and is ready for use!");
	}

	onScriptError() {
		console.log("Something went long when loading the Google reCAPTCHA");
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.authNoticeService.setNotice(null);
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

	/**
	 * Form initalization
	 * Default params, validators
	 */
	initLoginForm() {
		// demo message to show

		this.loginForm = this.fb.group({
			email: [
				"",
				Validators.compose([
					Validators.required,
					// Validators.minLength(3),
					Validators.maxLength(320), // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
				]),
			],
			password: [
				"",
				Validators.compose([
					Validators.required,
					// Validators.minLength(3),
					Validators.maxLength(100),
				]),
			],
			myRecaptcha: [false, Validators.compose([Validators.required])],
		});
	}

	/**
	 * Form Submit
	 */
	submit() {
		const controls = this.loginForm.controls;
		if (this.loginForm.invalid) {
			Object.keys(controls).forEach((controlName) =>
				controls[controlName].markAsTouched()
			);
			if(!controls['myRecaptcha'].value){
				this.toastr.error('Please Select Recaptcha ', 'Oops!', {
					progressBar: true,
					positionClass : 'toast-top-right',
					timeOut:15000
				});
			}
			return;
		}
		this.loading = true;
		const authData = {
			email: controls["email"].value,
			password: controls["password"].value,
		};

		this.globalservice.checkLogin(authData.email, authData.password,'user').then(
			(result) => {
				localStorage.setItem("currentuser", JSON.stringify(result));
				this.auth.currentUserSubject.next(result)
				this.globalservice.isLogin = true;
				this.loading = false;
				var udata = JSON.parse(localStorage.getItem("currentuser"));
				this.globalservice.userCredit = udata["credit"];
				this.globalservice.accountNumber =
					udata["user_info"]["account_no"];
				this.globalservice.userType = udata["user_info"]["user_type"];
				if (this.globalservice.userType === "admin") {
					if (
						this.globalservice.redirectUrl &&
						this.globalservice.redirectUrl.includes("admin")
					) {
						this.router.navigate([this.globalservice.redirectUrl]);
						this.globalservice.redirectUrl = null;
						localStorage.removeItem("last_user");
						localStorage.removeItem("checkSwitchPanel");
					} else {
						this.router.navigate(["/admin/dashboard"]);
						localStorage.removeItem("last_user");
						localStorage.removeItem("checkSwitchPanel");
					}
				} else {
					if (udata["user_info"]["force_reset_password"] == 1) {
						this.router.navigate(["/profile"]);
						this.globalservice.redirectUrl = null;
						localStorage.removeItem("last_user");
						localStorage.removeItem("checkSwitchPanel");
					} else {
						if (
							this.globalservice.redirectUrl &&
							!this.globalservice.redirectUrl.includes("admin")
						) {
							this.router.navigate([
								this.globalservice.redirectUrl,
							]);
							this.globalservice.redirectUrl = null;
							localStorage.removeItem("last_user");
							localStorage.removeItem("checkSwitchPanel");
						} else {
							this.router.navigate(["/dashboard"]);
							localStorage.removeItem("last_user");
							localStorage.removeItem("checkSwitchPanel");
						}
					}
				}
			},
			(err) => {
				this.toastr.error(err.error.message, "Oops!", {
					progressBar: true,
					positionClass: "toast-top-right",
					timeOut: 15000,
				});
				this.loading = false;
				if (err.error.status === "Token is Expired") {
					this.globalservice.terminateUser();
					return;
				}
			}
		);
	}

	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.loginForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result =
			control.hasError(validationType) &&
			(control.dirty || control.touched);
		return result;
	}
}
